

import { writeFile, utils } from "xlsx";


export const dateFormatter = (date) => {
    const d = new Date(date);
    // yyyy-mm-dd
    return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
  };

  export const now = (date = new Date()) => {
    return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
      }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };


export function ExportToExcel(fileName, data) {
  let wb = utils.book_new();
  let ws = utils.json_to_sheet(data);
  utils.book_append_sheet(wb, ws, "sheet");
  writeFile(wb, `${fileName}.xlsx`);
}



export const excelMapping = (data, columns) => {
  const arr = [];
  data.forEach((record) => {
    let obj = {};
    columns.forEach((key) => {
      obj[key.text] = record[key.dataField];
    });
    arr.push(obj);
  });
  return arr;
};
