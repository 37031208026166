'use client'
import React, { useContext, useEffect, useState } from "react";
import { Badge, Button, Modal, Form, FormControl } from "react-bootstrap";
import {
  Box,
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Loader from "../Loader";
// import "react-datepicker/dist/react-datepicker.css";
// import Swal from "sweetalert2";
// import { useAuth } from "../../context/AuthContext";
// import { ReduxContext } from "../../redux/ReduxStore";

import { Link } from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';
import Menu from 'components/menu/MainMenu';
import { AndroidLogo, AppleLogo, WindowsLogo } from 'components/icons/Icons';
import axios from 'axios';
import { ReduxContext } from "contexts/redux/ReduxState";

// const arr = [{ label: "Name", value: "Name" }];
const columnHelper = createColumnHelper();

export default function ViewAllWebhooks(props) {
  const { user } = React.useContext(ReduxContext);
  const [transactionLoaderState, setTransactionLoaderState] = useState(false);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const iconColor = useColorModeValue('secondaryGray.500', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [events, setEvents] = useState([]);
  const [webhookUrl, setWebhookUrl] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("");
  const [webhookUrlError, setWebhookUrlError] = useState("");
  const [selectedEventError, setSelectedEventError] = useState("");
  const [loading, setLoading] = useState(true);


  const ViewAllMerchant = async () => {
setTransactionLoaderState(true)
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        merchantId: user?.merchantId,
        // merchantId: "2",
        merchantSecret: "3DjyvAQvTZlk86g5jvHI7dfS",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://bumppy.in/payin/api/getAllMerchantWebhooks.php`,
        requestOptions
      );
      const data = await res.json();
      console.log("Merchant", data);
      if (data.status === "00") {
        setData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };
  const ViewAllMerchantEvents = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        merchantId: user?.merchantId,
        // merchantId: "2",
        merchantSecret: "3DjyvAQvTZlk86g5jvHI7dfS",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://bumppy.in/payin/api/getAllMerchantWebhookEvents.php`,
        requestOptions
      );
      const eventsData = await res.json();
      console.log("dhdh", eventsData);
      if (eventsData.status === "00") {
        setEvents(eventsData.data);
        setTransactionLoaderState(false)
      } else {
        console.error("Error fetching events:", eventsData.message);
        setTransactionLoaderState(false)
      }
    } catch (error) {
      console.error("Error fetching events:", error);
      setTransactionLoaderState(false)
    }
  };

  const handleAddWebhookClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const isValidForm = () => {
    setWebhookUrlError("");
    setSelectedEventError("");

    let isValid = true;

    if (!webhookUrl.trim()) {
      setWebhookUrlError("Webhook URL is required.");
      isValid = false;
    }
    if (selectedEvent.trim() == "") {
      setWebhookUrlError("Webhook Event is required.");
      isValid = false;
    }

    return isValid;
  };
  const addWebhook = async () => {
    try {
      if (!isValidForm()) {
        return;
      }

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        merchantId: user?.merchantId,
        // merchantId: "2",
        merchantSecret: "3DjyvAQvTZlk86g5jvHI7dfS",
        webhook_url: webhookUrl,
        event: selectedEvent,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://bumppy.in/payin/api/addMerchantWebhookUrl.php",
        requestOptions
      );

      const responseData = await res.json();

      if (responseData.status === "00") {
        // Swal.fire({
        //   icon: "success",
        //   title: "Add Successfully",
        //   text: "Webhook added successfully!",
        // });
        ViewAllMerchant();
        handleCloseModal();
      } else {
        console.error("Error adding webhook:", responseData.message);
      }
    } catch (error) {
      console.error("Error adding webhook:", error);
    }
  };
  useEffect(() => {
    ViewAllMerchant();
    ViewAllMerchantEvents();
  }, []);
  useEffect(() => { }, [data, events]);

  const headers = [
    { id: 'id', label: 'Sr' },
    { id: 'merchantId', label: 'Webhook URL' },
    { id: 'businessName', label: 'User ID' },
    { id: 'balance', label: 'Is Active' },
    { id: 'date', label: 'Created On' },
    { id: 'Updated', label: 'Updated On' },
    { id: 'Type', label: 'Webhook Type' },
  ];

  console.log(selectedEvent);
  return (
    
      <div>
      {transactionLoaderState ? (
        <Loader />
      ) : (
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
      // style={{ marginTop: "5rem" }}
      >
        <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
            // className='mt-4' s
          >
            View All Webhooks
          </Text>
        </Flex>
        <Box>
          <Table variant="simple" color="gray.500" mb="24px" mt="12px">
            <Thead>
              <Tr>
                {headers.map((header) => (
                  <Th
                    key={header.id}
                    pe="10px"
                    borderColor={borderColor}
                    cursor="pointer"
                  >
                    <Flex
                      justifyContent="space-between"
                      align="center"
                      fontSize={{ sm: '15px', lg: '12px' }}
                      color="gray.400"
                    >
                      {header.label}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {data.map((item, index) => (
                <Tr
                  key={item._id}
                  style={{
                    border: "none",
                    height: "3rem",
                    backgroundColor: "white",
                    color: "black"
                  }}
                >
                  <Td
                    style={{ border: "none" }}
                    className="text-center align-middle"
                    fontSize={{ sm: '12px', lg: '13px' }}
                  >
                    {index + 1}
                  </Td>
                  <Td
                    style={{ border: "none" , width:"3rem" }}
                    className="text-center align-middle"
                    fontSize={{ sm: '12px', lg: '13px' }}

                  >
                    {item.webhookUrl}
                  </Td>
                  <Td
                    style={{ border: "none" }}
                    className="text-center align-middle"
                    fontSize={{ sm: '12px', lg: '13px' }}

                  >
                    {item.userId}

                  </Td>
                  <Td
                    style={{ border: "none" }}
                    className="text-center align-middle"
                    fontSize={{ sm: '12px', lg: '13px' }}

                  >
                    {item.IsActive || "NA"}
                  </Td>
                  <Td
                    style={{ border: "none" }}
                    className="text-center align-middle"
                    fontSize={{ sm: '12px', lg: '13px' }}

                  >
                    {new Date(item.CreatedOn).toLocaleDateString() || "NA"}

                  </Td>
                  <Td
                    style={{ border: "none" }}
                    className="text-center align-middle"
                    fontSize={{ sm: '12px', lg: '13px' }}

                  >
                    {new Date(item.UpdatedOn).toLocaleDateString() || "NA"}

                  </Td>
                  <Td
                    style={{ border: "none" }}
                    className="text-center align-middle"
                    fontSize={{ sm: '12px', lg: '13px' }}

                  >
                    {item.webhook_type || "NA"}

                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Card>
  )}
      </div>
    
   
  );
};

