import React from 'react';

export default (state, action) => {
  console.log(action);
  switch (action.type) {
    case 'SET_USER_INFO':
      return { ...state, user: action.payload };
    case 'SET_PAYOUT_USER_INFO':
      return { ...state, user2: action.payload };
    case 'REMOVE_USER_INFO':
      return { ...state, user: action.payload };
    case 'SET_TRANSACTION_LOADER':
      return { ...state, transactionLoader: action.payload };
    default:
      return state;
  }
};
