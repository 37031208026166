import React, { useState, useEffect, useContext } from "react";

// import './api_partner/custom-tabs.css';
// import { useAuth } from "../context/AuthContext";
// import AllUPIIntentReport from "./AllUPIIntentReport";
import AllUPIIntentReport from "./ALLUpiIntentReport";

const PayinReport = () => {
    const [agentId, setAgentId] = useState("");

    // let {
    //     user2: info,
    //   } = useAuth();

    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };
    const [name, setName] = useState('');


    return (


        <div
        // style={{
        //   fontFamily: 'Arial, sans-serif',
        //   backgroundColor: '#f0f0f0',
        //   display: 'flex',
        //   justifyContent: 'center',
        //   alignItems: 'center',
        //   height: '100vh',
        //   margin: 0,
        //   width: "100%",
        // }}
      >
        <div
          style={{
            backgroundColor: '#fff',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            width: '100%',
            padding: '20px',
            textAlign: 'center',
            marginTop: '4rem',
          }}
        >
     {/* Tab Navigation */}
<div
  style={{
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '20px',
  }}
>
  <div
    onClick={() => handleTabClick(0)}
    style={{
      flex: 1,
      padding: '10px',
      cursor: 'pointer',
      backgroundColor: activeTab === 0 ? '#2c3e50' : '#f0f0f0', 
      color: activeTab === 0 ? '#fff' : '#000',
      border: 'none',
      fontSize: '16px',
      outline: 'none',
      transition: 'background-color 0.3s ease',
      borderRadius: '4px',
    }}
  >
    SUCCESS
  </div>
  <div
    onClick={() => handleTabClick(1)}
    style={{
      flex: 1,
      padding: '10px',
      cursor: 'pointer',
      backgroundColor: activeTab === 1 ? '#2c3e50' : '#f0f0f0', 
      color: activeTab === 1 ? '#fff' : '#000',
      border: 'none',
      fontSize: '16px',
      outline: 'none',
      transition: 'background-color 0.3s ease',
      borderRadius: '4px',
    }}
  >
    EXPIRED
  </div>
  <div
    onClick={() => handleTabClick(2)}
    style={{
      flex: 1,
      padding: '10px',
      cursor: 'pointer',
      backgroundColor: activeTab === 2 ? '#2c3e50' : '#f0f0f0', 
      color: activeTab === 2 ? '#fff' : '#000',
      border: 'none',
      fontSize: '16px',
      outline: 'none',
      transition: 'background-color 0.3s ease',
      borderRadius: '4px',
    }}
  >
    PENDING
  </div>
  <div
    onClick={() => handleTabClick(3)}
    style={{
      flex: 1,
      padding: '10px',
      cursor: 'pointer',
      backgroundColor: activeTab === 3 ? '#2c3e50' : '#f0f0f0', 
      color: activeTab === 3 ? '#fff' : '#000',
      border: 'none',
      fontSize: '16px',
      outline: 'none',
      transition: 'background-color 0.3s ease',
      borderRadius: '4px',
    }}
  >
    REFUND
  </div>
  <div
    onClick={() => handleTabClick(4)}
    style={{
      flex: 1,
      padding: '10px',
      cursor: 'pointer',
      backgroundColor: activeTab === 4 ? '#2c3e50' : '#f0f0f0', 
      color: activeTab === 4 ? '#fff' : '#000',
      border: 'none',
      fontSize: '16px',
      outline: 'none',
      transition: 'background-color 0.3s ease',
      borderRadius: '4px',
    }}
  >
    DISPUTE
  </div>
</div>


      
          {/* Tab Content */}
          <div
            style={{
              fontSize: '18px',
              padding: '20px',
              borderRadius: '10px',
              backgroundColor: '#fafafa',
            }}
          >
            {activeTab === 0 && <AllUPIIntentReport status={'SUCCESS'} />}
            {activeTab === 1 && <AllUPIIntentReport status={'EXPIRED'} />}
            {activeTab === 2 && <AllUPIIntentReport status={'N'} />}
            {activeTab === 3 && <AllUPIIntentReport status={'REFUND'} />}
            {activeTab === 4 && <AllUPIIntentReport status={'DISPUTE'} />}
          </div>
        </div>
      </div>

    );
};

export default PayinReport;
