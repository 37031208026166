import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './assets/css/App.css';
import { ReduxProvider, ReduxContext } from './contexts/redux/ReduxState'

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
      <ReduxProvider>
      <ReduxContext.Consumer>
        {(context) => (

          <App />
        )}
      </ReduxContext.Consumer>
    </ReduxProvider>
  </BrowserRouter>,
);
