import React, { useContext } from "react";
// import { useAuth } from "../context/AuthContext";
import {
  Box,
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Input,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router-dom";
import axios from "axios";
// import { Pagination } from "./utils/Pagination";
import Swal from "sweetalert2";
import { Select } from "@chakra-ui/react";
// import { offsetArr } from "../../utils/CommonConfig";
// import ComingSoonImage from "../assets/animation/Coming Soon (1).json";
// import Lottie from "lottie-react";
import { Button, Form } from "react-bootstrap";
// import { ExportToExcel, excelMapping, now } from "./utils/utilFuctions";
// import { ReduxContext } from "../../redux/ReduxStore";
import Loader from "../dataTables/Loader";
import { ReduxContext } from "contexts/redux/ReduxState";
import { Pagination } from "components/utils/Pagination";
import { now } from "components/utils/utilFunctions";
import { ExportToExcel } from "components/utils/utilFunctions";


const arr = [{ label: "Name", value: "Name" }];

const options = [
  { label: "Success", value: "success" },
  { label: "Failed", value: "n" },
]

export const columns = [
  {
    dataField: "ref_id",
    text: "Ref Id",
    color: "#586275",
  },
  {
    dataField: "rrn",
    text: "RRN",
    color: "#586275",
  },
  {
    dataField: "opening_bal",
    text: "Opening Bal",
    color: "#586275",
  },
  {
    dataField: "amount",
    text: "Transaction amount",
    color: "#586275",
  },
  {
    dataField: "charge",
    text: "Charges",
    color: "#586275",
  },
  {
    dataField: "GST",
    text: "GST",
    color: "#586275",
  },
  {
    dataField: "closing_bal",
    text: "Closing Bal",
    color: "#586275",
  },

  {
    dataField: "date",
    text: "Date",
    color: "#586275",
  },
  {
    dataField: "status",
    text: "Status",
    color: "#586275",
  }
];

const SettlementPay = ({ status }) => {
  const { user , setTransactionLoaderState } = React.useContext(ReduxContext);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [reportData, setReportData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [data, setData] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const iconColor = useColorModeValue('secondaryGray.500', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const [endDate, setEndDate] = useState(new Date());
  const [settlementButton, setsettlementButton] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [total, setTotal] = useState(0);
  const [pages, setPages] = useState(0);

  const location = useLocation();
  const merchantId = new URLSearchParams(location.search).get(
    "merchantId"
  );

  const dateFormatter = (d) => {
    const date = new Date(d);
    return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
      }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  //Settlement Report of Client
  const reportApi = async () => {
    setLoading(true);
    const rawData = {
      // "merchantId": "2",
      merchantId:user?.MerchentID,
      fromDate: dateFormatter(startDate),
      toDate: dateFormatter(endDate),
    };

    try {
      setTransactionLoaderState(true);
      const response = await axios.post(
        `https://bumppy.in/payin/api/sdkClientSettlementRecords.php`,
        rawData
      );

      if (response.data.status === "00") {
        setReportData(response.data.data);
        setsettlementButton("true");
      } else {
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.error("Error fetching report data:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const updateSettlementStatus = async (ledgerId, status) => {
    console.log("ledgerId", ledgerId, "status", status);
    try {
      let apiStatus = status === "SETTLED" ? "UNSETTLED" : "SETTLED";
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        ledgerId: ledgerId,
        status: apiStatus,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://bumppy.in/payin/api/updatePayinSettlement.php",
        requestOptions
      );

      const responseData = await res.json();

      if (responseData.status === "00") {
        Swal.fire({
          icon: "success",
          title: "SUCCESS",
          text: responseData.msg,
        });
        GetMerchantLedgerReport();
      } else {
        console.error("Error adding webhook:", responseData.message);
      }
    } catch (error) {
      console.error("Error adding webhook:", error);
    }
  };

  const calculateSettlement = async (ledgerId) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        ledgerId: ledgerId,
        status: "",
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://bumppy.in/payin/api/calculatePayinSettlement.php",
        requestOptions
      );

      const responseData = await res.json();

      if (responseData.status === "00") {
        Swal.fire({
          icon: "success",
          title: "SUCCESS",
          text: responseData.msg,
        });
        GetMerchantLedgerReport();
      } else {
        console.error("Error adding webhook:", responseData.message);
      }
    } catch (error) {
      console.error("Error adding webhook:", error);
    }
  };

  const initiateSettlement = async (ledgerId, settlement, status) => {
    try {
      let apiStatus = status === "N" ? "Y" : "N";
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        ledgerId: ledgerId,
        settlement: settlement,
        status: apiStatus,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://bumppy.in/payin/api/initiatePayinSettlement.php",
        requestOptions
      );

      const responseData = await res.json();

      if (responseData.status == "00") {
        Swal.fire({
          icon: "success",
          title: "SUCCESS",
          text: responseData.msg,
        });
        GetMerchantLedgerReport();
      } else {
        console.error("Error adding webhook:", responseData.message);
      }
      // ViewUPIIntentReport();
    } catch (error) {
      console.error("Error adding webhook:", error);
    }
  };

  const GetMerchantLedgerReport = async () => {

    const rawData = {
      merchant_id: merchantId || user?.merchantId,
    };

    try {
       setTransactionLoaderState(true);
      const response = await axios.post(
        `https://payinnew.remittpe.com/api/Intent/GetMerchantLedgerReport`,
        rawData,
      );

      if (response.status === 200) {
        if (response.data.status == '0') {
          setData(response.data.data);
        }
      } else {
      }
       setTransactionLoaderState(false);
    } catch (error) {
       setTransactionLoaderState(false);
      console.error('Error fetching report data:', error);
    } finally {
       setTransactionLoaderState(false);
    }
    // setData(data.data)
  };
  useEffect(() => {
    GetMerchantLedgerReport();
  }, []);

  //Pay All Settlement Api
  const PayAllApi = async () => {
    setLoading(true);
    const rawData = {
      // merchantId: "2",
      merchantId: user?.MerchentID,
      fromDate: dateFormatter(startDate),
      toDate: dateFormatter(endDate),
    };

    try {
      const response = await axios.post(
        ` https://bumppy.in/payin/api/sdkClientSettlementAdmin.php`,
        rawData
      );

      if (response.data.status === "00") {
        setReportData(response.data.data); // Assuming the data property holds the report data
      } else {
      }
    } catch (error) {
      console.error("Error fetching report data:", error);
    } finally {
      setLoading(false); // Set loading to false when API call completes (success or failure)
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Call the API with the selected date range

    reportApi();
  };

  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [data]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + 999) / 1000));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = 999;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage, offset]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  const positionStyle = {
    position: "absolute",
    top: -40,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };
  const positionStyle1 = {
    position: "absolute",
    top: -40,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };

  const headers = [
    { id: 'id', label: 'ID' },
    { id: 'PayinAmount', label: 'Payin Amount' },
    { id: 'PayinCharge', label: 'Payin Charge' },
    { id: 'Payin Gst', label: 'Payin Gst	' },
    { id: 'SettlementAmount', label: 'Settlement Amount' },
    { id: 'Transactiontime', label: 'Transaction Time' },
    { id: 'Status', label: 'Status' },
    { id: 'LastUpdated', label: 'Date' },
  ];

  return (
    <>
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflow="visible"
        minHeight="400px"
      >

        <Box>
          <div id="filters" style={{ display: "flex", alignItems: "center", gap: "1rem", margin: "1rem 0" }}>
            <Flex px="25px" mb="8px" justifyContent="space-between" align="center" style={{ flexGrow: 1 }}>
              <Text
                color={textColor}
                fontSize="22px"
                fontWeight="700"
                lineHeight="100%"
                className='mt-4'
              >
                Settlement Pay :
              </Text>
            </Flex>

            <div className="col-12 col-lg-6">
                  <Input
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: "0px", md: "0px" }}
                    type="text"
                    name="search"
                    value={searchText}
                    onChange={(e)=>{
                      setSearchText(e.target.value)
                    }}
                    placeholder="search"
                    // mb="24px"
                    fontWeight="500"
                    size="lg"
                  />
                </div>

            <Form.Group id="parentDatePicker" style={{ display: "flex", gap: "1rem", width: "2rem", flexGrow: 1 }}>
              <DatePicker
                className="form-control"
                style={{
                  borderColor: "#ddd",
                  padding: "0.5rem",
                  height: "40px",
                  borderRadius: "5px",
                  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
                  flexGrow: 1
                }}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />

              <DatePicker
                className="form-control"
                style={{
                  borderColor: "#ddd",
                  padding: "0.5rem",
                  height: "40px",
                  borderRadius: "5px",
                  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
                  flexGrow: 1
                }}
                selected={endDate}
                onChange={(date) => setEndDate(date)}
              />
            </Form.Group>

            <div className="d-flex align-items-center" id="searchButton" style={{ minWidth: "120px" }}>
              <Button
                className="btn btn-primary btn-lg"
                style={{
                  fontSize: "0.8rem",
                  height: "40px",
                  padding: "0.5rem 1rem",
                  backgroundColor: "rgb(22, 29, 111)",
                  borderColor: "none",
                  color: "#fff",
                  borderRadius: "5px",
                  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
                  width: "100%"
                }}
                onClick={GetMerchantLedgerReport}
              >
                Search
              </Button>
            </div>

            <div
              className="d-flex align-items-center justify-content-center "
              style={{
                cursor: "pointer",
                border: "1px solid rgba(43, 193, 85, 1)",
                padding: "0.5rem 1rem",
                height: "40px",
                backgroundColor: "rgba(43, 193, 85, 0.1)",
                color: "rgba(43, 193, 85, 1)",
                borderRadius: "5px",
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
                width: "8rem",
                marginRight: "1rem"
              }}
              onClick={()=>{
                ExportToExcel( `Settlement_${now()}`,data)
            }}
            >
              <strong style={{ fontSize: "0.8rem" }}>Export to Excel</strong>
            </div>
          </div>

          <div style={{ overflowX: "auto", maxWidth: "100%" }}> {/* Scrollable container */}
            <Table variant="simple" color="gray.500" mb="24px" mt="12px">
              <Thead>
                <Tr>
                  {headers.map((header) => (
                    <Th
                      key={header.id}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: '10px', lg: '12px' }}
                        color="gray.400"
                      >
                        {header.label}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {dataToDisplay.map((item, index) => (
                  <Tr
                    key={item._id}
                    style={{
                      border: "none",
                      height: "3rem",
                      backgroundColor: "white",
                      color: "black",
                    }}
                  >
                    <Td
                      style={{ border: "none" }}
                      className="text-center align-middle"
                      fontSize={{ sm: '10px', lg: '13px' }}
                    >
                      {item.id || "NA"}
                    </Td>
                    <Td
                      style={{ border: "none" }}
                      className="text-center align-middle"
                      fontSize={{ sm: '10px', lg: '13px' }}
                    >
                      {item.payin_amount || "NA"}
                    </Td>
                    <Td
                      style={{ border: "none" }}
                      className="text-center align-middle"
                      fontSize={{ sm: '10px', lg: '13px' }}
                    >
                      {item.payin_charge !== undefined
                        ? parseFloat(item.payin_charge).toFixed(2)
                        : "NA"}
                    </Td>
                    <Td
                      style={{ border: "none" }}
                      className="text-center align-middle"
                      fontSize={{ sm: '10px', lg: '13px' }}
                    >
                      {item.payin_gst !== undefined
                        ? parseFloat(item.payin_gst).toFixed(2)
                        : "NA"}
                    </Td>
                    <Td
                      style={{ border: "none" }}
                      className="text-center align-middle"
                      fontSize={{ sm: '10px', lg: '13px' }}
                    >
                      {item.payin_amount !== undefined && item.payin_charge !== undefined ? (
                        <span>
                          {parseFloat(item.payin_amount) - parseFloat(item.payin_charge)}
                        </span>
                      ) : (
                        <span>N/A</span> 
                      )}  
                    </Td>

                    <Td
                      style={{ border: "none" }}
                      className="text-center align-middle"
                      fontSize={{ sm: '10px', lg: '13px' }}
                    >
                      {item.settlement_time}
                    </Td>
                    <Td
                      style={{ border: "none" }}
                      className="text-center align-middle"
                      fontSize={{ sm: '10px', lg: '13px' }}
                    >
                      {item.status !== undefined &&
                        item.status.toString().toLowerCase() === "settled" ? (
                        <label
                          style={{
                            padding: "0.5rem",
                            width: "100px",
                            textAlign: "center",
                            border: "1px solid rgba(43, 193, 85, 1)",
                            backgroundColor: "#28a745",
                            color: "#fff",
                            borderRadius: ".4rem",
                            fontSize: ".8rem",
                            display: "inline-block",
                          }}
                          className="badge badge-success"
                        >
                          {item.status}
                        </label>
                      ) : item.status !== undefined &&
                        item.status.toString().toLowerCase() === "unsettled" ? (
                        <label
                          style={{
                            padding: "0.5rem",
                            width: "100px",
                            textAlign: "center",
                            borderRadius: ".4rem",
                            backgroundColor: "#C80036", // Red for unsettled
                            color: "#fff", // White text
                            border: "1px solid #C80036",
                            fontSize: "0.8rem",
                            display: "inline-block",
                          }}
                          className="badge badge-danger"
                        >
                          {item.status}
                        </label>
                      ) : (
                        <label
                          style={{
                            padding: "0.5rem",
                            width: "100px",
                            textAlign: "center",
                            borderRadius: ".4rem",
                            backgroundColor: "#808080", // Grey for other statuses
                            color: "#fff", // White text
                            border: "1px solid #808080",
                            fontSize: "0.8rem",
                            display: "inline-block",
                          }}
                          className="badge badge-secondary"
                        >
                          {item.status || "Unknown"}
                        </label>
                      )}
                    </Td>

                    <Td
                      style={{ border: "none" }}
                      className="text-center align-middle"
                      fontSize={{ sm: '10px', lg: '13px' }}
                    >
                      {item.update}
                    </Td>

                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Pagination
              page={currentPage}
              limit={pages}
              callback={(page) => {
                setCurrentPage(page);
              }}
              count={dataToDisplay.length}
              total={total}
              callback2={(offsetValue) => {
                setOffset(offsetValue);
              }}
            />
          </div>

        </Box>
      </Card>



    </>
  );
};

export default SettlementPay;

// export const Pagination = ({
//   page,
//   limit,
//   callback,
//   total,
//   count,
//   callback2,
// }) => {
//   console.table({ page, limit, callback });
//   return (
//     <div
//       className="row"
//       style={{
//         display: "flex",
//         alignItems: "center",
//         width: "100%",
//         margin: "10px 0px",
//         justifyContent: "space-between",
//       }}
//     >
//       <div
//         className="d-flex"
//         style={{
//           borderRadius: "4px",
//           marginTop: "5px",
//           overflow: "hidden",
//         }}
//       >
//         <select
//           className="form-control"
//           style={{ border: "none", outline: "none" }}
//           aria-label="offset"
//           onChange={(e) => {
//             callback2(e.target.value);
//           }}
//         >
//           {offsetArr.map((offset) => (
//             <option value={offset}>{offset}</option>
//           ))}
//         </select>
//         {page - 2 > 0 && (
//           <Button
//             onClick={() => {
//               callback(page - 1);
//             }}
//             variant="light"
//             style={{ border: "none", backgroundColor: "#f8f9fa" }}
//           >
//             Previous
//           </Button>
//         )}
//         {page - 1 > 0 && (
//           <Button
//             onClick={() => {
//               callback(page - 1);
//             }}
//             variant="light"
//             style={{ border: "none", backgroundColor: "#f8f9fa" }}
//           >
//             {(page - 1).toString()}
//           </Button>
//         )}
//         <Button
//           variant="light"
//           style={{
//             backgroundColor: "#33334d",
//             color: "#fff",
//             borderRadius: "0px",
//           }}
//         >
//           {page.toString()}
//         </Button>
//         {page + 1 <= limit && (
//           <Button
//             onClick={() => {
//               callback(page + 1);
//             }}
//             variant="light"
//             style={{
//               border: "none",
//               backgroundColor: "#f8f9fa",
//               boxShadow: "none",
//             }}
//           >
//             Next
//           </Button>
//         )}
//       </div>
//       <div>
//         <strong style={{ color: "#33334d" }}>{`Showing ${count} of ${total} entries`}</strong>
//       </div>
//     </div>

//   );
// };