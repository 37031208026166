import React, { createContext, useReducer } from 'react';
import AppReducer from './AppReducer';

const sessionUser = JSON.parse(sessionStorage.getItem('user'));
const sessionUser2 = JSON.parse(sessionStorage.getItem('user2'));

const initialState = {
  user: sessionUser,
  user2: sessionUser2,
};
export const ReduxContext = createContext(initialState);

export const ReduxProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  function setTransactionLoaderState(loaderState) {
    // dispatch({
    //   type: 'SET_TRANSACTION_LOADER',
    //   payload: loaderState,
    // });
  }

  function login(info) {
    sessionStorage.setItem('user', JSON.stringify(info));
    dispatch({
      type: 'SET_USER_INFO',
      payload: info,
    });
  }

  const login2 = (userCredentials) => {
    // Store the user data in localStorage
    sessionStorage.setItem("user2", JSON.stringify(userCredentials));
    dispatch({
      type: 'SET_PAYOUT_USER_INFO',
      payload: userCredentials,
    });
  };


  function removeUserInfo() {
    sessionStorage.removeItem('user');
    dispatch({
      type: 'REMOVE_USER_INFO',
      payload: null,
    });
  }

  return (
    <ReduxContext.Provider
      value={{
        user: state.user,
        login,
        loginEvent2 : login2,
        user2: state.user2,
        removeUserInfo,
        transactionLoader: state.transactionLoader,
        setTransactionLoaderState,
      }}
    >
      {children}
    </ReduxContext.Provider>
  );
};
