'use client';
import React, { useContext, useEffect, useState } from 'react';
// import { offsetArr } from "../../utils/CommonConfig";
import {
  Box,
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Input,
  useColorModeValue,
} from '@chakra-ui/react';
import { Link } from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Button, Form } from 'react-bootstrap';
import Card from 'components/card/Card';
import Menu from 'components/menu/MainMenu';
import { AndroidLogo, AppleLogo, WindowsLogo } from 'components/icons/Icons';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import { useLocation } from 'react-router-dom';
// import Select from "react-select";
import { Select } from '@chakra-ui/react';
// import swal from "sweetalert";
import Swal from 'sweetalert2';
import 'react-datepicker/dist/react-datepicker.css';
// import { ReduxContext } from "../../redux/ReduxStore";
import Loader from '../Loader';
import { ReduxContext } from 'contexts/redux/ReduxState';
import { Pagination } from 'components/utils/Pagination';
import { ExportToExcel } from 'components/utils/utilFunctions';
import { now } from 'components/utils/utilFunctions';

const arr = [{ label: 'Name', value: 'Name' }];

const options = [
  { label: 'Success', value: 'success' },
  { label: 'Failed', value: 'n' },
];

export default function AllUPIIntentReport(props) {
  const { user, setTransactionLoaderState } = React.useContext(ReduxContext);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const iconColor = useColorModeValue('secondaryGray.500', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const [reportData, setReportData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [type, setType] = useState('1');
  const [data, setData] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [total, setTotal] = useState(0);
  const [pages, setPages] = useState(0);
  const [ledger, setLedger] = useState([]);

  const location = useLocation();
  const merchantId = new URLSearchParams(location.search).get(
    "merchantId"
  );


  const [formData, setFormData] = useState({
    merchantSecret: '',
    ip_address: '',
  });

  const dateFormatter = (d) => {
    const date = new Date(d);
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : '0' + date.getDate()}`;
  };

  useEffect(() => {
    setPages(Math.floor((ledger.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(ledger.length);
    const filteredData = ledger.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [ledger]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + 999) / 1000));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = 999;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage, offset]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  useEffect(() => {
    setPages(Math.floor((ledger.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(ledger.length);
    const filteredData = ledger.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [ledger]);

  const ViewUPIIntentReport = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');

      const raw = JSON.stringify({
        merchant_id: merchantId || user?.merchantId,
        // merchant_id: "2",
        from_date: dateFormatter(startDate),
        to_date: dateFormatter(endDate),
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      };
      setTransactionLoaderState(true)
      const res = await fetch(
        `https://payinnew.remittpe.com/api/Intent/GetAllUpiIntentReport`,
        requestOptions,
      );
      const data = await res.json();
      console.log('Merchant', data);
      if (data.status === '0') {
        setData(data.data);
        setDataToDisplay(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log('error', error);
      setLoading(false);
      setTransactionLoaderState(false);
    }
  };

  const fetchTransactionStatus = async (txnt) => {
    try {
      setTransactionLoaderState(true);
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append(
        'Authorization',
        'Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1',
      );

      var raw = JSON.stringify({
        merchantId: user?.merchantId,
        // merchantId: "2",
        referenceId: txnt.ref_id,
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      };

      const res = await fetch(
        `https://payinnew.remittpe.com/api/Intent/fetchTransactionStatus`,
        requestOptions,
      );
      const data = await res.json();
      const {
        transactionStatus,
        bankReference,
        refrenceId,
        vpa,
        transactionAmount,
      } = data.data;
      Swal({
        title: 'Transaction Status Details',
        text: `
          Status: ${transactionStatus}
          Bank Reference: ${bankReference}
          Reference ID: ${refrenceId}
          VPA: ${vpa}
          Transaction Amount: ${transactionAmount}
        `,
        icon:
          transactionStatus.toLowerCase() === 'success' ? 'success' : 'info',
        button: 'OK',
      });
    } catch (error) {
      console.error('Error fetching status:', error);
      Swal('Error', 'Failed to fetch transaction status', 'error');
      setTransactionLoaderState(false);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    ViewUPIIntentReport();
  }, []);

  useEffect(() => {
    if (selectedStatus) {
      const filteredData = data.filter((e) => {
        return e.status.toString().toLowerCase() == selectedStatus.value;
      });
      setDataToDisplay(filteredData);
    }
  }, [selectedStatus]);

  const headers = [
    { id: 'id', label: 'Sr' },
    { id: 'RefId', label: 'Ref Id' },
    { id: 'RRN', label: 'RRN' },
    { id: 'Amount', label: 'Amount' },
    { id: 'Charges', label: 'Charges' },
    { id: 'GST', label: 'GST' },
    { id: 'Date', label: 'Date' },
    { id: 'Status', label: 'Status' },
    { id: 'Action', label: 'Action' },
  ];

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <Card
          flexDirection="column"
          w="100%"
          px="0px"
          overflow="visible"
          minHeight="400px"
        >
          <Box>
            <div
              id="filters"
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                margin: '1rem 0',
              }}
            >
              <Flex
                px="25px"
                mb="8px"
                justifyContent="space-between"
                align="center"
                style={{ flexGrow: 1 }}
              >
                <Text
                  color={textColor}
                  fontSize="22px"
                  fontWeight="700"
                  lineHeight="100%"
                  className="mt-4"
                >
                  UPI Intent Report
                </Text>
              </Flex>

              <div className="col-12 col-lg-6">
                  <Input
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: "0px", md: "0px" }}
                    type="text"
                    name="search"
                    value={searchText}
                    onChange={(e)=>{
                      setSearchText(e.target.value)
                    }}
                    placeholder="search"
                    // mb="24px"
                    fontWeight="500"
                    size="lg"
                  />
                </div>

              <Form.Group
                id="parentDatePicker"
                style={{
                  display: 'flex',
                  gap: '1rem',
                  width: '2rem',
                  flexGrow: 1,
                }}
              >
                <DatePicker
                  className="form-control"
                  style={{
                    borderColor: '#ddd',
                    padding: '0.5rem',
                    height: '40px',
                    borderRadius: '5px',
                    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
                    flexGrow: 1,
                  }}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />

                <DatePicker
                  className="form-control"
                  style={{
                    borderColor: '#ddd',
                    padding: '0.5rem',
                    height: '40px',
                    borderRadius: '5px',
                    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
                    flexGrow: 1,
                  }}
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                />
              </Form.Group>

              <div
                className="d-flex align-items-center"
                id="searchButton"
                style={{ minWidth: '120px' }}
              >
                <Button
                  className="btn btn-primary btn-lg"
                  style={{
                    fontSize: '0.8rem',
                    height: '40px',
                    padding: '0.5rem 1rem',
                    backgroundColor: 'rgb(22, 29, 111)',
                    borderColor: 'none',
                    color: '#fff',
                    borderRadius: '5px',
                    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
                    width: '100%',
                  }}
                  onClick={ViewUPIIntentReport}
                >
                  Search
                </Button>
              </div>

              <div
                className="d-flex align-items-center justify-content-center "
                style={{
                  cursor: 'pointer',
                  border: '1px solid rgba(43, 193, 85, 1)',
                  padding: '0.5rem 1rem',
                  height: '40px',
                  backgroundColor: 'rgba(43, 193, 85, 0.1)',
                  color: 'rgba(43, 193, 85, 1)',
                  borderRadius: '5px',
                  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
                  width: '8rem',
                  marginRight: '1rem',
                }}
                onClick={()=>{
                    ExportToExcel( `UPIIntentReport_${now()}`,data)
                }}
              >
                <strong style={{ fontSize: '0.8rem' }}>Export to Excel</strong>
              </div>
            </div>

            <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
              {' '}
              {/* Scrollable container */}
              <Table variant="simple" color="gray.500" mb="24px" mt="12px">
                <Thead>
                  <Tr>
                    {headers.map((header) => (
                      <Th
                        key={header.id}
                        pe="10px"
                        borderColor={borderColor}
                        cursor="pointer"
                      >
                        <Flex
                          justifyContent="space-between"
                          align="center"
                          fontSize={{ sm: '10px', lg: '12px' }}
                          color="gray.400"
                        >
                          {header.label}
                        </Flex>
                      </Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {dataToDisplay.map((item, index) => (
                    <Tr
                      key={item._id}
                      style={{
                        border: 'none',
                        height: '3rem',
                        backgroundColor: 'white',
                        color: 'black',
                      }}
                    >
                      <Td
                        style={{ border: 'none' }}
                        className="text-center align-middle"
                        fontSize={{ sm: '10px', lg: '13px' }}
                      >
                        {index + 1}
                      </Td>
                      <Td
                        style={{ border: 'none' }}
                        className="text-center align-middle"
                        fontSize={{ sm: '10px', lg: '13px' }}
                      >
                        {item.ref_id || 'NA'}
                      </Td>
                      <Td
                        style={{ border: 'none' }}
                        className="text-center align-middle"
                        fontSize={{ sm: '10px', lg: '13px' }}
                      >
                        {item.rrn || 'NA'}
                      </Td>
                      <Td
                        style={{ border: 'none' }}
                        className="text-center align-middle"
                        fontSize={{ sm: '10px', lg: '13px' }}
                      >
                        {item.amount !== undefined
                          ? parseFloat(item.amount).toFixed(2)
                          : 'NA'}
                      </Td>
                      <Td
                        style={{ border: 'none' }}
                        className="text-center align-middle"
                        fontSize={{ sm: '10px', lg: '13px' }}
                      >
                        {item.charge !== undefined
                          ? parseFloat(item.charge).toFixed(2)
                          : 'NA'}
                      </Td>
                      <Td
                        style={{ border: 'none' }}
                        className="text-center align-middle"
                        fontSize={{ sm: '10px', lg: '13px' }}
                      >
                        {item.gst !== undefined
                          ? parseFloat(item.gst).toFixed(2)
                          : 'NA'}
                      </Td>
                      <Td
                        style={{ border: 'none' }}
                        className="text-center align-middle"
                        fontSize={{ sm: '10px', lg: '13px' }}
                      >
                        {item.date || 'NA'}
                      </Td>
                      <Td
                        style={{ border: 'none' }}
                        className="text-center align-middle"
                        fontSize={{ sm: '10px', lg: '13px' }}
                      >
                        {item.status !== undefined &&
                        item.status.toString().toLowerCase() === 'success' ? (
                          <label
                            style={{
                              padding: '0.5rem',
                              width: '100px',
                              textAlign: 'center',
                              border: '1px solid rgba(43, 193, 85, 1)',
                              backgroundColor: '#28a745', // Green for success
                              color: '#fff', // White text
                              borderRadius: '.4rem',
                              fontSize: '.8rem',
                              display: 'inline-block',
                            }}
                            className="badge badge-success"
                          >
                            {item.status}
                          </label>
                        ) : item.status !== undefined &&
                          item.status
                            .toString()
                            .toLowerCase()
                            .includes('expire') ? (
                          <label
                            style={{
                              padding: '0.5rem',
                              width: '100px',
                              textAlign: 'center',
                              borderRadius: '.4rem',
                              backgroundColor: '#C80036', // Red for expired
                              color: '#fff', // White text
                              border: '1px solid #C80036',
                              fontSize: '0.8rem',
                              display: 'inline-block',
                            }}
                            className="badge badge-danger"
                          >
                            {item.status}
                          </label>
                        ) : item.status !== undefined && item.status === '' ? (
                          <label
                            style={{
                              padding: '0.5rem',
                              width: '100px',
                              textAlign: 'center',
                              borderRadius: '.4rem',
                              backgroundColor: '#FFD700', // Yellow for failed
                              color: '#fff', // White text
                              border: '1px solid #FFD700',
                              fontSize: '0.8rem',
                              display: 'inline-block',
                            }}
                            className="badge badge-warning"
                          >
                            {'Failed'}
                          </label>
                        ) : (
                          <label
                            style={{
                              padding: '0.5rem',
                              width: '100px',
                              textAlign: 'center',
                              borderRadius: '.4rem',
                              backgroundColor: '#808080', // Grey for unknown status
                              color: '#fff', // White text
                              border: '1px solid #808080',
                              fontSize: '0.8rem',
                              display: 'inline-block',
                            }}
                            className="badge badge-secondary"
                          >
                            {item.status}
                          </label>
                        )}
                      </Td>
                      <Td
                        style={{ border: 'none' }}
                        className="text-center align-middle"
                        fontSize={{ sm: '10px', lg: '13px' }}
                      >
                        <button
                          type="button"
                          className="btn px-2"
                          onClick={() => fetchTransactionStatus(item)}
                          style={{
                            color: 'white',
                            background: '#161D6F',
                            padding: '0.5rem',
                            width: '100px',
                            textAlign: 'center',
                            fontSize: '0.8rem',
                            display: 'inline-block',
                          }}
                        >
                          Fetch Status
                        </button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <Pagination
                page={currentPage}
                limit={pages}
                callback={(page) => {
                  setCurrentPage(page);
                }}
                count={dataToDisplay.length}
                total={total}
                callback2={(offsetValue) => {
                  setOffset(offsetValue);
                }}
              />
            </div>
          </Box>
        </Card>
      )}
    </div>
  );
}
