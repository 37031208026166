
import { useEffect, useState } from 'react';
import {
  Flex,
  Box,
  Table,
  Checkbox,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import * as React from 'react';
import { Link } from '@chakra-ui/react';
import axios from 'axios';
import { Badge, Button, Modal } from "react-bootstrap";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

// Custom components
import Card from 'components/card/Card';
import Menu from 'components/menu/MainMenu';
import { easeIn } from 'framer-motion';
import { ReduxContext } from 'contexts/redux/ReduxState';
import withSession from '../../../../withSession';

const columnHelper = createColumnHelper();

const  CheckTable = ({analyticsData}) => {
  const [data, setData] = useState([]);
  const [allMerchantList, setallMerchantList] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [balanceValues, setBalanceValues] = useState("");
  const [offset, setOffset] = useState(2);
  const [total, setTotal] = useState(0)
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [ledger, setLedger] = useState([]);
  const [error, setError] = useState(null);

  // const { tableData } = props;
  const [sorting, setSorting] = useState([]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const iconColor = useColorModeValue('secondaryGray.500', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  // let defaultData = tableData;
  const columns = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Sr
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('merchantId', {
      id: 'merchantId',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Merchant Id
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('businessName', {
      id: 'businessName',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Business Name
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('balance', {
      id: 'balance',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Payment API Balance
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue() || 'NA'}
        </Text>
      ),
    }),
    columnHelper.accessor('date', {
      id: 'date',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          OnBoarding Date
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
  ];

  useEffect(() => {
    setPages(Math.floor((ledger.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(ledger.length);
    const filteredData = ledger.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [ledger]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + 999) / 1000));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = 999;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage, offset]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  useEffect(() => {
    setPages(Math.floor((ledger.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(ledger.length);
    const filteredData = ledger.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [ledger]);


  // const [data, setData] = React.useState(() => [...defaultData]);


  const headers = [
    { id: 'total_payin', label: 'Total Business' },
    { id: 'total_charge', label: 'Charges' },
    { id: 'total_gst', label: 'GST' },
    { id: 'slab', label: 'Slab' },
  ];
  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    // style={{ marginTop: "5rem" }}
    >
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
          className='mt-4'
        >
         Settlement Slabs </Text>
      </Flex>
      <Box>
        <Table variant="simple" color="gray.500" mb="24px" mt="12px">
          <Thead>
            <Tr>
              {headers.map((header) => (
                <Th
                  key={header.id}
                  pe="10px"
                  borderColor={borderColor}
                  cursor="pointer"
                >
                  <Flex
                    justifyContent="space-between"
                    align="center"
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color="gray.400"
                  >
                    {header.label}
                  </Flex>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {analyticsData.map((e, ind) => (
              <Tr
                key={e._id}
                style={{
                  border: "none",
                  height: "3rem",
                  backgroundColor: "white",
                  color: "black"
                }}
              >
                <Td
                  style={{ border: "none" }}
                  className="text-center align-middle"
                  fontSize={{ sm: '10px', lg: '13px' }}
                >
                  {e.total_payin || "NA"}
                </Td>
                <Td
                  style={{ border: "none" }}
                  className="text-center align-middle"
                  fontSize={{ sm: '10px', lg: '13px' }}
                >
                  {e.total_charge}
                </Td>
                <Td
                  style={{ border: "none" }}
                  className="text-center align-middle"
                  fontSize={{ sm: '10px', lg: '13px' }}
                >
                  {e.total_gst}
                </Td>
                <Td
                  style={{ border: "none" }}
                  className="text-center align-middle"
                  fontSize={{ sm: '10px', lg: '13px' }}
                >
                  {e.slab}
                </Td>

              </Tr>
            ))}
          </Tbody>
        </Table>
      
      </Box>
    </Card>
  );
}

const Pagination = ({
  page,
  limit,
  callback,
  total,
  count,
  callback2,
}) => {
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        margin: "8px 0",
        justifyContent: "space-between",
        padding: "0 10px", // Add padding on left and right
      }}
    >
      <div
        className="d-flex"
        style={{
          borderRadius: "4px",
          overflow: "hidden",
          alignItems: "center", // Center items vertically
        }}
      >
        <select
          className="form-control"
          style={{
            border: "1px solid #ccc",
            outline: "none",
            width: "50px",
            height: "28px",
            fontSize: "12px",
            margin: "0 4px",
            padding: "0", // Remove padding for better alignment
            lineHeight: "28px", // Align text vertically
          }}
          aria-label="offset"
          onChange={(e) => callback2(e.target.value)}
        >
          {offsetArr.map((offset) => (
            <option key={offset} value={offset}>{offset}</option>
          ))}
        </select>
        
        {page > 1 && (
          <Button
            onClick={() => callback(page - 1)}
            variant="light"
            style={{
              border: "1px solid #ccc",
              backgroundColor: "#f8f9fa",
              height: "28px",
              padding: "0 5px",
              fontSize: "12px",
              margin: "0 2px",
              lineHeight: "28px", // Align text vertically
            }}
          >
            Previous
          </Button>
        )}
        
        <Button
          variant="light"
          style={{
            border: "1px solid #33334d",
            backgroundColor: "#33334d",
            color: "#fff",
            height: "28px",
            padding: "0 5px",
            fontSize: "12px",
            margin: "0 2px",
            lineHeight: "28px", // Align text vertically
          }}
        >
          {page}
        </Button>
        
        {page < limit && (
          <Button
            onClick={() => callback(page + 1)}
            variant="light"
            style={{
              border: "1px solid #ccc",
              backgroundColor: "#f8f9fa",
              height: "28px",
              padding: "0 5px",
              fontSize: "12px",
              margin: "0 2px",
              lineHeight: "28px", // Align text vertically
            }}
          >
            Next
          </Button>
        )}
      </div>
      
      <div style={{ fontSize: "12px", color: "#33334d", marginLeft: "10px" }}>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
    </div>
  );
};


export const offsetArr = [10, 25, 50, 100, 200, 250, 500, 1000,10000,25000];

export default withSession(CheckTable);
