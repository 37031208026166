import React, { useContext, useEffect, useState } from 'react';
import { Tabs, Tab, Form, Modal } from 'react-bootstrap';
// import Select from "react-select";
import { Select } from '@chakra-ui/react';
import Swal from 'sweetalert2';
import {
  Box,
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Input,
  InputGroup,
  Tr,
  FormLabel,
  InputRightElement,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { RiEyeCloseLine } from 'react-icons/ri';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { ReduxContext } from 'contexts/redux/ReduxState';

// import { useAuth } from "../../context/AuthContext";
// import { ReduxContext } from "../../redux/ReduxStore";
export default function TransactionDetails(props) {
  const { user  , setTransactionLoaderState} = React.useContext(ReduxContext);

  // const { user, setTransactionLoaderState } = useContext(ReduxContext);
  const [passwordError, setPasswordError] = useState('');

  const [data, setData] = useState('');
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    txnid: '',
  });

  const [formError, setFormError] = useState({
    txnid: '',
  });
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const [show, setShow] = React.useState(false);

  const textColorSecondary = 'gray.400';
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const iconColor = useColorModeValue('secondaryGray.500', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === 'password' || name === 'confirmPassword') {
      setPasswordError('');
    }
  };

  const clearForm = () => {
    setFormData({
      businessname: '',
      email: '',
      password: '',
      confirmPassword: '',
      mobile: '',
    });
    setPasswordError('');
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    createMerchantApi();
  };

  const createMerchantApi = async (e) => {
    e.preventDefault(); 
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Basic cm9iaW4uNDM5MDAwMEBnbWFpbC5jb206S2F5YW5AOVhQMVg=");
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        merchantId: user?.merchantId,
        referenceId: formData.txnid,
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      };
      setTransactionLoaderState(true);
      const res = await fetch(
        'https://payinnew.remittpe.com/api/Intent/fetchTransactionStatus',
        requestOptions,
      );

      const data = await res.json();

      if(data.data){
        setTransactions([data.data]);
      }else{
        setTransactions([]);
      }

      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.error('Error fetching Form data:', error);
    }
    setLoading(false);
  };

  const cellStyle = {
    padding: '10px',
    fontSize: '12px',
    borderBottom: '1px solid #e0e0e0',
    color: '#555',
    textAlign: 'center',
  };

  const statusStyles = {
    success: {
      padding: '0.5rem',
      borderRadius: '.4rem',
      backgroundColor: '#28a745',
      color: '#fff',
      fontSize: '.8rem',
    },
    fail: {
      padding: '0.5rem',
      borderRadius: '.4rem',
      backgroundColor: '#C80036',
      color: '#fff',
      fontSize: '.8rem',
    },
    secondary: {
      padding: '0.5rem',
      borderRadius: '.4rem',
      backgroundColor: '#808080',
      color: '#fff',
      fontSize: '.8rem',
    },
  };
console.log('transactions', transactions);
  return (
    <>
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
      >
        <Box>
          <div className="full-width-container">
            <div className="row">
              <div
                className="col-12 col-lg-6 offset-lg-3"
                //   style={{ borderRadius: "10px", padding: "3rem" }}
              >
                <form>
                  <div className="form-title"> Transaction Details</div>

                  <div className="row">
                    <div className="col-12 col-lg-6 mb-3">
                      <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        mb="8px"
                      >
                        RefId or RRN<Text color={brandStars}>*</Text>
                      </FormLabel>
                      <Input
                        isRequired={true}
                        variant="auth"
                        fontSize="sm"
                        ms={{ base: '0px', md: '0px' }}
                        type="Number"
                        value={formData.txnid}
                        placeholder="Enter RefId or RRN"
                        onChange={(e) =>
                          setFormData({ ...formData, txnid: e.target.value })
                        }
                        mb="24px"
                        fontWeight="500"
                        size="lg"
                      />
                      {formError.txnid && (
                        <p style={{ color: 'red' }} className="error-message">
                          {formError.txnid}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="button-container row">
                    <button
                      className=" col btn btn-create rounded-2 "
                      style={{ color: 'white', background: '#161D6F' }}
                      onClick={createMerchantApi}
                    >
                      {' '}
                      Fetch Transaction Details
                    </button>
                    <button
                      className=" col btn btn-clear rounded-2"
                      style={{ color: 'white', background: '#C80036' }}
                    >
                      Clear Form
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Box>
      </Card>

      {/* Transaction details table */}
      {transactions.length > 0 && (
        <div
          className=" card container "
          style={{ width: '100%', marginTop: '2rem' }}
        >
          <h2
            className="mb-4 text-center"
            style={{ fontSize: '24px', fontWeight: '700', color: '#1A1A1A' }}
          >
            Transaction Details
          </h2>
          {transactions.map((transaction, index) => (
            <table
              className="table table-bordered mb-4"
              key={index}
              style={{
                borderRadius: '8px',
                overflow: 'hidden',
                backgroundColor: '#ffffff',
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                borderCollapse: 'collapse',
                width: '100%',
              }}
            >
              <thead>
                <tr
                  style={{
                    backgroundColor: '#f2f2f2',
                    borderBottom: '2px solid #e0e0e0',
                  }}
                >
                  <th
                    style={{
                      padding: '10px',
                      fontSize: '14px',
                      fontWeight: '600',
                      color: '#333',
                    }}
                  >
                    Field
                  </th>
                  <th
                    style={{
                      padding: '10px',
                      fontSize: '14px',
                      fontWeight: '600',
                      color: '#333',
                    }}
                  >
                    Value
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th style={cellStyle}>Refrence ID</th>
                  <td style={cellStyle}>{transaction.refrenceId || 'NA'}</td>
                </tr>
                <tr>
                  <th style={cellStyle}>VPA</th>
                  <td style={cellStyle}>{transaction.vpa || 'NA'}</td>
                </tr>
                <tr>
                  <th style={cellStyle}>Transaction Amount</th>
                  <td style={cellStyle}>
                    {transaction.transactionAmount || 'NA'}
                  </td>
                </tr>
                <tr>
                  <th style={cellStyle}>Bank Reference</th>
                  <td style={cellStyle}>{transaction.bankReference || 'NA'}</td>
                </tr>

                <tr>
                  <th style={cellStyle}>Status</th>
                  <td style={cellStyle}>
                    {transaction.transactionStatus !== undefined &&
                    transaction.transactionStatus === 'SUCCESS' ? (
                      <label
                        style={{
                          padding: '0.5rem',
                          width: '100px',
                          textAlign: 'center',
                          border: '1px solid rgba(43, 193, 85, 1)',
                          backgroundColor: '#28a745',
                          color: '#fff',
                          borderRadius: '.4rem',
                          fontSize: '.8rem',
                          display: 'inline-block',
                        }}
                        className="badge badge-success"
                      >
                        {transaction.transactionStatus}
                      </label>
                    ) : transaction.transactionStatus !== undefined &&
                      transaction.transactionStatus === 'Fail' ? (
                      <label
                        style={{
                          padding: '0.5rem',
                          width: '100px',
                          textAlign: 'center',
                          borderRadius: '.4rem',
                          backgroundColor: '#C80036',
                          color: '#fff',
                          border: '1px solid #C80036',
                          fontSize: '0.8rem',
                          display: 'inline-block',
                        }}
                        className="badge badge-danger"
                      >
                        {transaction.transactionStatus}
                      </label>
                    ) : (
                      <label
                        style={{
                          padding: '0.5rem',
                          width: '100px',
                          textAlign: 'center',
                          borderRadius: '.4rem',
                          backgroundColor: '#808080',
                          color: '#fff',
                          border: '1px solid #808080',
                          fontSize: '0.8rem',
                          display: 'inline-block',
                        }}
                        className="badge badge-secondary"
                      >
                        {transaction.transactionStatus || 'Unknown'}
                      </label>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          ))}
        </div>
      )}
    </>
  );
}
