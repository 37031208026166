import React from "react";
import image from "../../assets/animation/404.gif"

const containerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    margin: "0",
    backgroundColor: "#f8f9fa",
    textAlign: "center",
  };

  const imgStyle = {
    width: "300px",
    height: "auto",
  };

  const textStyle = {
    fontSize: "24px",
    color: "#333",
    marginTop: "20px",
  };

export function ErrorPage() {
  return (
    <div style={containerStyle}>
      <img
        style={imgStyle}
        src={image}
        alt="404 Error GIF"
      />
      <h2 style={textStyle}>Page Not Found</h2>
    </div>
  );
}

export default ErrorPage;
