import React from 'react';
import Lottie from 'lottie-react';

import loaderAnimation from '../../../assets/animation/Loading.json'; 

const Loader = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Lottie animationData={loaderAnimation} loop={true} />
    </div>
  );
};

export default Loader;