import Loader from 'components/utils/Loader';
import React, { useState , useCallback} from 'react';

export default function withSession(Component) {
    const MemoizedComponent = React.memo(Component);
  return function LoaderComponent(props) {
    const [isLoading, setIsLoading] = useState(false);

    const setTransactionLoaderState = useCallback((loaderState) => {
        setIsLoading(loaderState);
      }, []);
    return isLoading ? (
      <Loader />
    ) : (
      <MemoizedComponent
        {...props}
        setTransactionLoaderState={setTransactionLoaderState}
        isLoading={isLoading}
      />
    );
  };
}
