
import { Badge, Button, Modal } from 'react-bootstrap';
export const offsetArr = [10, 25, 50, 100, 200, 250, 500, 1000, 10000, 25000];

export const Pagination = ({
    page,
    limit,
    callback,
    total,
    count,
    callback2,
  }) => {
    return (
      <div
        className="row"
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          margin: '10px 0px',
          justifyContent: 'space-between',
        }}
      >
        <div
          className="d-flex justify-content-between"
          style={{
            borderRadius: '4px',
            marginTop: '5px',
            overflow: 'hidden',
          }}
        >
          <select
            className="form-control"
            style={{ outline: 'none' , maxWidth:'6rem' }}
            aria-label="offset"
            onChange={(e) => {
              callback2(e.target.value);
            }}
          >
            {offsetArr.map((offset) => (
              <option value={offset}>{offset}</option>
            ))}
          </select>
          <div  className="w-100"></div>
          {page - 2 > 0 && (
            <Button
              onClick={() => {
                callback(page - 1);
              }}
              className='btn-sm'
              variant="light"
              style={{ border: 'none', backgroundColor: '#f8f9fa' }}
            >
              Previous
            </Button>
          )}
          {page - 1 > 0 && (
            <Button
              onClick={() => {
                callback(page - 1);
              }}
              className='btn-sm'
              variant="light"
              style={{ border: 'none', backgroundColor: '#f8f9fa' }}
            >
              {(page - 1).toString()}
            </Button>
          )}
          <Button
            variant="light"
            className='btn-sm'
            style={{
              backgroundColor: '#33334d',
              color: '#fff',
              borderRadius: '0px',
            }}
          >
            {page.toString()}
          </Button>
          {page + 1 <= limit && (
            <Button
              onClick={() => {
                callback(page + 1);
              }}
              className='btn-sm'
              variant="light"
              style={{
                border: 'none',
                backgroundColor: '#f8f9fa',
                boxShadow: 'none',
              }}
            >
              Next
            </Button>
          )}
        </div>
        <div>
          <strong
            style={{ color: '#33334d' }}
          >{`Showing ${count} of ${total} entries`}</strong>
        </div>
      </div>
    );
  };
  
