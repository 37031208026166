/* eslint-disable */
import React , {useContext }from "react";
import { NavLink, useLocation } from "react-router-dom";
// chakra imports
import { Box, Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";
import { ReduxContext } from "../../../contexts/redux/ReduxState";
import { Icon } from '@chakra-ui/react';
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
} from 'react-icons/md';

export function SidebarLinks(props) {
  // const { user } = useContext(ReduxContext)
  const { user  } = React.useContext(ReduxContext);

  //   Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("brand.500", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");

  // const { routes } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  // const routes = [
  //   {
  //     name: 'Main Dashboard',
  //     layout: '/admin',
  //     path: '/198235201/dashboard',
  //     icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
  //   },
  //   {
  //     name: 'Client Ledger',
  //     layout: '/admin',
  //     path: '/198235201/payin_ledger',
  //     icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
  //   },
  //   {
  //     name: 'Settlements',
  //     layout: '/admin',
  //     path: '/198235201/settlement_process',
  //     icon: (
  //       <Icon
  //         as={MdOutlineShoppingCart}
  //         width="20px"
  //         height="20px"
  //         color="inherit"
  //       />
  //     ),
  //     secondary: true,
  //   },
  
    
   
  //   {
  //     name: 'All clients',
  //     layout: '/198235201',
  //     icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
  //     path: '/all_clients',
  //   },
  //   {
  //     name: 'Create Client',
  //     layout: '/admin',
  //     path: '/create_client',
  //     icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
  //   },
  //   {
  //     name: 'Developer',
  //     layout: '/198235201',
  //     path: '/developer',
  //     icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
  //   },
  //   {
  //     name: 'Load Request',
  //     layout: '/198235201',
  //     path: '/load_request',
  //     icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
  //   },
  //   {
  //     name: 'Pay-In',
  //     layout: '/198235201',
  //     path: '/payin_report',
  //     icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
  //   },
  //   {
  //     name: 'Transaction Details',
  //     layout: '/198235201',
  //     path: '/transaction_query',
  //     icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
  //   },
  //   {
  //     name: 'Pay-out',
  //     layout: '/198235201',
  //     path: '/payout',
  //     icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
  //   },
  //   {
  //     name: 'Logout ',
  //     layout: '/198235201',
  //     path: '/logout',
  //     icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
  //   }
    
  // ];


  const userId = user?.merchantId; 

  let routes;
  
  if (userId === "2") {
    routes = [
      {
        name: 'Main Dashboard',
        layout: '/admin',
        path: '/198235201/dashboard',
        icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
      },
      {
        name: 'Client Ledger',
        layout: '/admin',
        path: '/198235201/payin_ledger',
        icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
      },
      {
        name: 'Settlements',
        layout: '/admin',
        path: '/198235201/settlement_process',
        icon: (
          <Icon
            as={MdOutlineShoppingCart}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
        secondary: true,
      },
      {
        name: 'All clients',
        layout: '/198235201',
        icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
        path: '/all_clients',
      },
      {
        name: 'Create Client',
        layout: '/admin',
        path: '/create_client',
        icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
      },
      {
        name: 'Developer',
        layout: '/198235201',
        path: '/developer',
        icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
      },
      {
        name: 'Load Request',
        layout: '/198235201',
        path: '/load_request',
        icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
      },
      {
        name: 'Pay-In',
        layout: '/198235201',
        path: '/payin_report',
        icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
      },
      {
        name: 'Transaction Details',
        layout: '/198235201',
        path: '/transaction_query',
        icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
      },
      {
        name: 'Pay-out',
        layout: '/198235201',
        path: '/payout',
        icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
      },
      {
        name: 'Logout',
        layout: '/198235201',
        path: '/logout',
        icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
      },
    ];
  } else {
    routes = [
      {
        name: 'Main Dashboard',
        layout: '/admin',
        path: '/198235201/dashboard',
        icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
      },
      {
        name: 'Client Ledger',
        layout: '/admin',
        path: '/198235201/payin_ledger',
        icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
      },
      {
        name: 'Create Client',
        layout: '/admin',
        path: '/create_client',
        icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
      },
      {
        name: 'Developer',
        layout: '/198235201',
        path: '/developer',
        icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
      },
      {
        name: 'Load Request',
        layout: '/198235201',
        path: '/load_request',
        icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
      },
      {
        name: 'Pay-In',
        layout: '/198235201',
        path: '/payin_report',
        icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
      },
      {
        name: 'Transaction Details',
        layout: '/198235201',
        path: '/transaction_query',
        icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
      },
      {
        name: 'Pay-out',
        layout: '/198235201',
        path: '/payout',
        icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
      },
      {
        name: 'Logout',
        layout: '/198235201',
        path: '/logout',
        icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
      },
    ];
  }
  
  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (route.category) {
        return (
          <>
            <Text
              fontSize={"md"}
              color={activeColor}
              fontWeight='bold'
              mx='auto'
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              pt='18px'
              pb='12px'
              key={index}>
              {route.name}
            </Text>
            {createLinks(route.items)}
          </>
        );
      } else if (
        route.layout === "/198235201" ||
        route.layout === "/auth" ||
        route.layout === "/rtl"
      ) {
        return (
          <NavLink key={index} to={route.layout + route.path}>
            {route.icon ? (
              <Box onClick={()=>{
                if(route.name="Logout "){
                 // removeUserInfo();
                }
              }}>
                <HStack
                  spacing={
                    activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                  }
                  py='5px'
                  ps='10px'>
                  <Flex w='100%' alignItems='center' justifyContent='center'>
                    <Box
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeIcon
                          : textColor
                      }
                      me='18px'>
                      {route.icon}
                    </Box>
                    <Text
                      me='auto'
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeColor
                          : textColor
                      }
                      fontWeight={
                        activeRoute(route.path.toLowerCase())
                          ? "bold"
                          : "normal"
                      }>
                      {route.name}
                    </Text>
                  </Flex>
                  <Box
                    h='36px'
                    w='4px'
                    bg={
                      activeRoute(route.path.toLowerCase())
                        ? brandColor
                        : "transparent"
                    }
                    borderRadius='5px'
                  />
                </HStack>
              </Box>
            ) : (
              <Box>
                <HStack
                  spacing={
                    activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                  }
                  py='5px'
                  ps='10px'>
                  <Text
                    me='auto'
                    color={
                      activeRoute(route.path.toLowerCase())
                        ? activeColor
                        : inactiveColor
                    }
                    fontWeight={
                      activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
                    }>
                    {route.name}
                  </Text>
                  <Box h='36px' w='4px' bg='brand.400' borderRadius='5px' />
                </HStack>
              </Box>
            )}
          </NavLink>
        );
      }
    });
  };
  //  BRAND
  return createLinks(routes);
}

export default SidebarLinks;