import React, { useState, useEffect, useContext } from 'react';
// import { urls } from "./devUrls";
// import './custom-tabs.css';
// import Transactions, { Payout } from "./Transactions";
import Transactions from './Transaction';
import { Payout } from './Payout';
import { Ledger } from './Ledger';
import Balances from './Balances';
import Profile from './Profile';
// import { ReduxContext } from "../../redux/ReduxStore";
import { useLocation } from 'react-router-dom';
import { Tabs, Tab, Button } from 'react-bootstrap';
import { urls } from './devUrls';
import { ReduxContext } from 'contexts/redux/ReduxState';

const MyAccount = () => {
  const [agentId, setAgentId] = useState('');
  const { user, loginEvent2, user2 } = useContext(ReduxContext);
  const merchantString = sessionStorage.getItem('merchant');
  const merchant = JSON.parse(merchantString);
  const location = useLocation();
  const payout_username = merchant?.payout_username;
  const payout_password = merchant?.payout_password;
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  // const { login: loginUrl } = urls;
  const [name, setName] = useState('');
  const [submittedData, setSubmittedData] = useState(null);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [auth, setAuth] = useState(true);
  const [invalid, setInvalid] = useState(false);

  const getTabStyle = (tabIndex) => ({
    flex: 1,
    padding: '10px',
    cursor: 'pointer',
    backgroundColor: activeTab === tabIndex ? '#2c3e50' : '#f0f0f0',
    color: activeTab === tabIndex ? '#fff' : '#000',
    fontSize: '16px',
    borderRadius: '4px',
    transition: 'background-color 0.3s ease',
  });
  const { GetUserProfileInfo: GetUserProfileInfoUrl , login : loginUrl} = urls;

  const getAccountInfo = async () => {
      const accessToken = sessionStorage.getItem("accessToken");
      const userId = sessionStorage.getItem("userId");
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${accessToken}`);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
          "Cookie",
          ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iza8XwzTtDsnycoa_JEX2ahOG_6MqIJje0CyELar_eYNiyc8DVdR7WY3yvlwQ9s5qK-G9UoNP6RdZTPbLL0E4Yy__ZCqoFSpUJsPMTzYhGoRbCBLEH3HiICuxNtXPw9IpsW5XRdNUA5lHOG3gBLa-TYZZUXKvhWs78Q25He_Q_MZj04E463t-zUOMNPH_2V9-HWesyARkYFQM9VWEp9jX0s96Zs-yKSE6RKVeiQVbD3QZbMk0tL6DKEREfo0FCVYaOMIGMvKX0GFGsPCXD7L63RcOqMuUCAUU2g1MenEVAWVqlP98WGqkSBtzkDMllZH809ERbz8nKyN9PlkAsXpj2KtJ2OnqWQ2_qr9UH-eFMBMtmRZ4Rcz3qP_Njkx4uwF9MOPeX9EGCoEqOcxTjp9jdC2GWIBON2t0K_Rz7tGFMXe8qGFpeOIZ9DKIZhcVeXiBOb70OBWIcsXQdPr-3rRRTePDDSieVuXUSWR2GnjosxJJqEvCga-MbD59KKJEOJ8cYr2E6dImBGcQuix6g8YCT_eijrXPKNEhZQttEzxYoe-0s0OypnpXEeuOnx9uKta06fT31SqLNomWInObIItLRF4mqH5ZNmnnKxHCatj8r4o7EUKlPTq9fH7D3F7w_Z6KENoOfTC2kz1q5VE7GHM78z"
      );

      var raw = JSON.stringify({
          userId: userId,
      });

      var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
      };

      try {

          const res = await fetch(GetUserProfileInfoUrl, requestOptions);
          const data = await res.json();
          loginEvent2(data.data);
      } catch (error) {
      }
  };

  const login = async () => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
          "Cookie",
          ".AspNetCore.Identity.Application=CfDJ8JsnH411h-hPvOBmxZ5uxqP6rp2ytIifypwWZTazYqnFXDYohzj3JhFUoUEyEcGcfpXoK2-0ijf3BfPQJXY7Nk86kTqeOrMMhiG8TINrH5E2C_nKDsku7O4JS7yDc2tSweTku1oPbP1jYCQ7aXuXI4rWYFu_iikYclB72RfTjJLAF64UAjWZ_va2rZZ_wvLHItEF2zvWTMqKayPDzIc44DwsgDYpPhaj4mDx1kNNk1OiItKIvPzVRU3DVcNiC2PckNQw2lVRod5mMErEu_LUI728kGjTcARb2vYmffdusRK-1f2thw0FOBPimLZTJq16HFGx8wBKNEgVSZJD-IwAym8IFHQgw-_FdhFBBzIdj4ovd8cbLbqBBIF_dJmVvCHL5a08sEcFeJcoa_HSYFlQDMF57fvS_EOVvnAe8SQz5l2BUuPk8Zwq0S1aePx6e0xY09i9l-g5zMPZgvX-cALm-TtrvhVKUkvnM2gXYabjX0po2NmepBzeSwioEcsuOZpTFGfcDwKlIXKclnPYGvVuVlSS1aJRFpKBzJu0SpbFIwKKab7W9nYwU752JuiZFIrZKJCa2G8kRo7P3qPHKAMFNbwfCNEVIbB5A_GwBU2pzhTVUeLiHcPkvFgxcGn1m_nzmxqivZyTey5K7Qvhhv38mESKZytL-ZOmLU-3Bggomr9d"
      );

      var raw = JSON.stringify({
          email: payout_username || user?.payout_username,
          password: payout_password || user?.payout_password,
      });

      var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
      };

      try {
          const result = await fetch(loginUrl, requestOptions);
          if (result.ok) {
              const body = await result.text();
              const data = JSON.parse(body);
              if (data.userName != undefined) {
                  sessionStorage.setItem("userName", data.userName);
              }
              if (data.role != undefined) {
                  sessionStorage.setItem("role", data.role);
              }
              if (data.accessToken != undefined && data.userId != undefined) {
                  sessionStorage.setItem("userId", data.userId);
                  // setToken(data.accessToken);
                  sessionStorage.setItem("accessToken", data.accessToken);
                  setAuth(true)
                  getAccountInfo();
              }
              if (data.refreshToken != undefined) {
                  sessionStorage.setItem("refreshToken", data.refreshToken);
              }
              sessionStorage.setItem(
                  "basic_auth",
                  JSON.stringify({ username: userName, password })
              );
              setRedirect(true);
          } else {
              setInvalid(true);
          }
      } catch (error) {
          console.log(error);
      }
  };

  useEffect(() => {
      login();
  }, []);

  if (!auth) {
    return null;
  } else {
    return (
      // <div
      //     // className="p-4 bg-light"
      //     style={{ fontFamily: "auto" }}>

      //     <div style={{ background: "#fff" }}>
      //         {" "}
      //         <div
      //             className="row justify-content-between mx-1 m-1 mb-3"
      //             style={{ background: "" }}
      //         >
      //             <h2 className="col"
      //                 style={{
      //                     margin: "0",
      //                     fontWeight: "bolder",
      //                 }}
      //             >
      //                 My Account
      //             </h2>
      //             <div className="col d-flex justify-content-end">
      //                 <button
      //                     className="  btn  "
      //                     //    onClick={logoutDeveloperLogin}
      //                     style={{ width: "7rem", background: "rgb(199, 37, 62)", color: "white" }}
      //                 >
      //                     Log Out
      //                 </button>
      //             </div>
      //         </div>
      //     </div>
      //     <div>
      //         <div>
      //             <div className=" m-0 p-0 pt-3 w-100">
      //                 <div className=" m-0 p-0">
      //                     <div className="">
      //                         <div defaultActiveKey="profile" className=" tab-header justify-content-between">
      //                             <div
      //                                 className={`tab-item ${activeTab === 0 ? "active" : ""}`}
      //                                 onClick={() => handleTabClick(0)}
      //                             >
      //                                 Profile
      //                             </div>
      //                             <div
      //                                 className={`tab-item ${activeTab === 1 ? "active" : ""}`}
      //                                 onClick={() => handleTabClick(1)}
      //                             >
      //                                 Balances
      //                             </div>
      //                             <div
      //                                 className={`tab-item ${activeTab === 2 ? "active" : ""}`}
      //                                 onClick={() => handleTabClick(2)}
      //                             >
      //                                 Ledger
      //                             </div>

      //                             <div
      //                                 className={`tab-item ${activeTab === 3 ? "active" : ""}`}
      //                                 onClick={() => handleTabClick(3)}
      //                             >
      //                                 Transactions
      //                             </div>

      //                             <div
      //                                 className={`tab-item ${activeTab === 4 ? "active" : ""}`}
      //                                 onClick={() => handleTabClick(4)}
      //                             >
      //                                 Payout
      //                             </div>

      //                         </div>
      //                         <div className="tab-content">
      //                             {activeTab === 0 && <div><Profile agentId={agentId} /></div>}
      //                             {activeTab === 1 && <div><Balances agentId={agentId} /></div>}
      //                             {activeTab === 2 && <div><Ledger agentId={agentId} /></div>}
      //                             {activeTab === 3 && <div> <Transactions agentId={agentId} /> </div>}
      //                             {activeTab === 4 && <div> <Payout agentId={agentId} /></div>}
      //                         </div>
      //                     </div>
      //                 </div>
      //             </div>

      //         </div>
      //     </div>

      // </div>
      <div style={{ fontFamily: 'auto' }}>
        <div
          style={{
            backgroundColor: '#fff',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            padding: '20px',
            marginTop: '4rem',
          }}
        >
          <div className="row justify-content-between mx-1 m-1 mb-3">
            <h1 style={{ margin: '0', fontWeight: 'bolder' }}>My Account</h1>
            {/* <div className="d-flex justify-content-end">
        <button style={{ width: "7rem", background: "rgb(199, 37, 62)", color: "white", borderRadius: "4px", padding: "10px" }}>Log Out</button>
      </div> */}
          </div>

          {/* Tab Navigation */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              marginBottom: '20px',
            }}
          >
            <div onClick={() => handleTabClick(0)} style={getTabStyle(0)}>
              Profile
            </div>
            <div onClick={() => handleTabClick(1)} style={getTabStyle(1)}>
              Balances
            </div>
            <div onClick={() => handleTabClick(2)} style={getTabStyle(2)}>
              Ledger
            </div>
            <div onClick={() => handleTabClick(3)} style={getTabStyle(3)}>
              Transactions
            </div>
            <div onClick={() => handleTabClick(4)} style={getTabStyle(4)}>
              Payout
            </div>
          </div>

          {/* Tab Content */}
          <div
            style={{
              fontSize: '18px',
              padding: '20px',
              borderRadius: '10px',
              backgroundColor: '#fafafa',
            }}
          >
            {activeTab === 0 && (
              <div>
                <Profile agentId={agentId} />
              </div>
            )}
            {activeTab === 1 && (
              <div>
                <Balances agentId={agentId} />
              </div>
            )}
            {activeTab === 2 && (
              <div>
                <Ledger agentId={agentId} />
              </div>
            )}
            {activeTab === 3 && (
              <div>
                <Transactions agentId={agentId} />
              </div>
            )}
            {activeTab === 4 && (
              <div>
                <Payout agentId={agentId} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default MyAccount;
