import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Card from 'components/card/Card';
import {
  Box,
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import { Pagination } from "components/utils/Pagination";
import { ExportToExcel } from "components/utils/utilFunctions";
import { now } from "components/utils/utilFunctions";
// import {
//   excelMapping,
//   ExportToExcel,
//   now,
//   twoDecimalPlaces,
// } from "../../utils/utilFuctions";
// import { offsetArr } from "../../utils/CommonConfig";
// import { ReduxContext } from "../../redux/ReduxStore";

export const columns = [
  {
    dataField: "id",
    text: "Txnt ID",
    color: "#586275",
  },
  {
    dataField: "opening_bal",
    text: "Opening Balance",
    color: "#586275",
  },
  {
    dataField: "payout_today",
    text: "Payout Today",
    color: "#586275",
  },
  {
    dataField: "payout_fee",
    text: "Payout Charges",
    color: "#586275",
  },
  {
    dataField: "today_topup",
    text: "Topup Today",
    color: "#586275",
  },
  {
    dataField: "today_refund",
    text: "Refund Today",
    color: "#586275",
  },
  {
    dataField: "settlement_today",
    text: "Settlement Today",
    color: "#586275",
  },
  {
    dataField: "payout_fee",
    text: "Payout Charges",
    color: "#586275",
  },
  {
    dataField: "payin_fee",
    text: "Payin Charges",
    color: "#586275",
  },
  {
    dataField: "closingBal",
    text: "Closing Balance",
    color: "#586275",
  },
  {
    dataField: "datevalue",
    text: "Date",
    color: "#586275",
  },
];

export const Ledger = ({ setDetails }) => {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const iconColor = useColorModeValue('secondaryGray.500', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const [offset, setOffset] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(getDatePreviousMonth());
  const [endDate, setEndDate] = useState(new Date());

  const [loading, setLoading] = useState(true);
  // const { setTransactionLoaderState } = useContext(ReduxContext);
  const [data, setData] = useState([]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date = new Date()) => {
    const mm = date.getMonth() + 1;
    return `${date.getFullYear()}-${mm > 9 ? mm : "0" + mm}-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()
      }`;
  };
  const getAllVirtualPayments = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iwOgwEj6EBN0jpchgFhuiV_O6Tcuf6QLM3cjarWosB3o17JTbrLuvcOmIJGLw0OwV-JJr2mhON-MIsfNGVplhmoJMIoQQrIyv6dzlCW8_eOvZw0ahT_icJcGsoHfR-1FLppZoY2SWzX8NUi9aabidVqbnsJjig4BpI2oPpnu73FH5iMh2Kfuz2xWcPSdx08C7D_6Ygts4I9XapyCvm0OVKguJ3fWPFHBgoY-PSnQ4KNzeh-OI4TzaffhkWCBwgBLtA6ybohQ1cqY5c2Y2EPo-sgAft8xvCDg9pibGVqcUdtEkqHj-BKKBdLijXEW-P7Q9e-T-wL07LWdnOnMPF9YCns79c8kBgyHTiVi82lFdqqIZtzZnhJsZoVhf9vsGaD37-6zhe4sDOkUJf4WCLur6gGQB8LQrfK9RZg30X0OvMv7ZCOtC4SKxgXiyk44NtkqY9oP_cVSb00F_E80WkQhk1A4nUfPalYivPP3EPF45TtK2hlhfdvdw_G1rMLrDiTbaIj-yvW5831ualRbsVUhVSHiUZzLTshMjUg-r_o5FNk4OSR2EyJm2l1WGwLdtZccqzvuyG_UzV9XiIYA658Nqy0iQX-ckKK4yR0yLPGXkmyx1MzMh9RjVPJwsRmhnIKqPE4eOFQNdJDYowJQhEQIMZV"
    );

    var raw = JSON.stringify({
      fromDate: dateFormatter(startDate),
      toDate: dateFormatter(endDate),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {

      setLoading(true);
      // setTransactionLoaderState(true)
      const res = await fetch(
        "https://apitranxt.paynnow.com/api/Payments/GetUserledger",
        requestOptions
      );
      console.log("response", res);
      if (res.status == 41) {
        //   removeMyAccountInfo();
      } else if (res.ok) {

        setLoading(false);
        const data = await res.json();
        console.log(data);
        if (data && data.length > 0) {
          const dataLoad = data.map((acc) => {
            return {
              ...acc,
              upi: `${acc.beneAccountNo}@yesbankltd`,
              date: new Date(acc.createdOn),
              closingBal: (
                Number(acc.opening_bal) +
                Number(acc.today_topup) +
                Number(acc.today_refund) -
                Number(acc.payout_today) -
                Number(acc.payout_fee) -
                Number(acc.payin_fee) +
                Number(acc.settlement_today)
              ),
            };
          });
          dataLoad.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
          });
          console.log(dataLoad);
          setData(dataLoad);
          setDataToDisplay(dataLoad);
        }
      }
      // setTransactionLoaderState(false)
      // setPayments(JSON.parse(body).data);
    } catch (error) {
      // setTransactionLoaderState(false)
      setLoading(false);
      console.log("41 catched", error);
    }
  };

  const openInNewTab = () => {
    const newWindow = window.open(
      `/pages/print?id=090422191784660&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  // did mount
  useEffect(() => {
     getAllVirtualPayments();
  }, []);
  console.log(data);
  return (

    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflow="visible"
      minHeight="400px"
    >

      <Box>
        <div id="filters" style={{ display: "flex", alignItems: "center", gap: "1rem", margin: "1rem 0" }}>
          <Flex px="25px" mb="8px" justifyContent="space-between" align="center" style={{ flexGrow: 1 }}>
            <Text
              color={textColor}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
              className='mt-4'
            >
             Ledger Report
            </Text>
          </Flex>

          <Form.Group id="parentDatePicker" style={{ display: "flex", gap: "1rem", width: "2rem", flexGrow: 1 }}>
            <DatePicker
              className="form-control"
              style={{
                borderColor: "#ddd",
                padding: "0.5rem",
                height: "40px",
                borderRadius: "5px",
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
                flexGrow: 1
              }}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />

            <DatePicker
              className="form-control"
              style={{
                borderColor: "#ddd",
                padding: "0.5rem",
                height: "40px",
                borderRadius: "5px",
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
                flexGrow: 1
              }}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </Form.Group>

          <div className="d-flex align-items-center" id="searchButton" style={{ minWidth: "120px" }}>
            <Button
              className="btn btn-primary btn-lg"
              style={{
                fontSize: "0.8rem",
                height: "40px",
                padding: "0.5rem 1rem",
                backgroundColor: "rgb(22, 29, 111)",
                borderColor: "none",
                color: "#fff",
                borderRadius: "5px",
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
                width: "100%"
              }}
              onClick={getAllVirtualPayments}
            >
              Search
            </Button>
          </div>

          <div
            className="d-flex align-items-center justify-content-center "
            style={{
              cursor: "pointer",
              border: "1px solid rgba(43, 193, 85, 1)",
              padding: "0.5rem 1rem",
              height: "40px",
              backgroundColor: "rgba(43, 193, 85, 0.1)",
              color: "rgba(43, 193, 85, 1)",
              borderRadius: "5px",
              boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
              width: "8rem",
              marginRight: "1rem"
            }}
            onClick={()=>{
              ExportToExcel(`LedgerReport_${now()}`,data)
          }}
          >
            <strong style={{ fontSize: "0.8rem" }}>Export to Excel</strong>
          </div>
        </div>

        <div style={{ overflowX: "auto", maxWidth: "100%" }}> {/* Scrollable container */}
          <Table variant="simple" color="gray.500" mb="24px" mt="12px">
            <Thead>
              <Tr>
                <Th borderColor={borderColor} pe="10px">Sr</Th>
                {columns.map((heading, index) => (
                  <Th key={index} borderColor={borderColor} cursor="pointer" pe="10px">
                    <Flex justifyContent="space-between" align="center" fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
                      {heading.text}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {loading && (
                <Tr>
                  <Td colSpan={columns.length + 1} textAlign="center">
                    <div className="loader"></div>
                  </Td>
                </Tr>
              )}
              {dataToDisplay.map((e, ind) => (
               <tr
               key={ind}
               style={{ border: "0.1px solid rgb(191 188 188)" }}>
               <td className="text-center align-middle" style={{ fontSize: ".8rem", border: "none" }}>
                 {ind + 1}
               </td>
               {columns.map((cell) => (
                 <td key={cell.dataField} style={{ border: "none" }}>
                   {cell.dataField === "status" ? (
                     <>
                       {(e[cell.dataField].toString().toLowerCase() === "refunded" ||
                         e[cell.dataField].toString().toLowerCase() === "refund") && (
                         <label
                           style={{
                             padding: "8px",
                             borderRadius: ".4rem",
                             backgroundColor: "rgba(0, 123, 255, 0.1)",
                             color: "rgba(0, 123, 255, 1)",
                             border: "1px solid rgba(0, 123, 255, 1)",
                             fontSize: ".8rem",
                           }}
                           className="badge badge-warning"
                         >
                           {e[cell.dataField]}
                         </label>
                       )}
                       {e[cell.dataField] === "SettlementCompleted" && (
                         <label
                           style={{
                             padding: "8px",
                             borderRadius: ".4rem",
                             backgroundColor: "rgba(43, 193, 85, 0.1)",
                             color: "rgba(43, 193, 85, 1)",
                             border: "1px solid rgba(43, 193, 85, 1)",
                             fontSize: ".8rem",
                           }}
                           className="badge badge-success"
                         >
                           {e[cell.dataField]}
                         </label>
                       )}
                       {(e[cell.dataField] === "Received" || e[cell.dataField] === "Accepted") && (
                         <label
                           style={{
                             padding: "8px",
                             borderRadius: ".4rem",
                             backgroundColor: "rgba(0, 123, 255, 0.1)",
                             color: "rgba(0, 123, 255, 1)",
                             border: "1px solid rgba(0, 123, 255, 1)",
                             fontSize: ".8rem",
                           }}
                           className="badge badge-warning"
                         >
                           {e[cell.dataField]}
                         </label>
                       )}
                       {e[cell.dataField].toString().toLowerCase() === "failed" && (
                         <label
                           style={{
                             padding: "8px",
                             borderRadius: ".4rem",
                             backgroundColor: "rgba(255, 0, 0, 0.1)",
                             color: "rgba(255, 0, 0, 1)",
                             border: "1px solid rgba(255, 0, 0, 1)",
                             fontSize: ".8rem",
                           }}
                           className="badge badge-danger"
                         >
                           Failed
                         </label>
                       )}
                       {e[cell.dataField] === "0" && (
                         <label
                           style={{
                             padding: "8px",
                             borderRadius: ".4rem",
                             backgroundColor: "rgba(0, 123, 255, 0.1)",
                             color: "rgba(0, 123, 255, 1)",
                             border: "1px solid rgba(0, 123, 255, 1)",
                             fontSize: ".8rem",
                           }}
                           className="badge badge-warning"
                         >
                           Virtual
                         </label>
                       )}
                     </>
                   ) : (
                     e[cell.dataField] || "NA"
                   )}
                 </td>
               ))}
             </tr>
             
              ))}
            </Tbody>
          </Table>
          <Pagination
              page={currentPage}
              limit={pages}
              callback={(page) => {
                setCurrentPage(page);
              }}
              count={dataToDisplay.length}
              total={total}
              callback2={(offsetValue) => {
                setOffset(offsetValue);
              }}
            />

        </div>

      </Box>
    </Card>

    // <div style={{ background: "white" }}>
    //   <div className="row m-2 p-0 pt-4 align-items-center" id="parentFilters">
    //     <div
    //       className="m-0 p-0 pl-2 flex-grow-1 d-flex"
    //       style={{
    //         fontFamily: "Poppins",
    //         fontSize: "28px",
    //         fontWeight: "600",
    //         lineHeight: "48px",
    //         textAlign: "left",
    //         background: "#FFFFFF",
    //         color: "#33334d",
    //         minWidth: "150px",
    //       }}
    //     >
    //       Ledger Report
    //     </div>

    //     <div id="filters" style={{ gap: "0.5rem", display: "flex", alignItems: "center" }}>
    //       <div id="searchBarStyle" className="m-1 p-0" style={{ flexBasis: "20%", minWidth: "150px" }}>
    //         <input
    //           type="text"
    //           className="form-control"
    //           placeholder="Search text..."
    //           style={{
    //             border: "1px solid #bfbcbc",
    //             padding: "0.5rem",
    //             height: "40px",
    //             width: "100%",
    //           }}
    //           onChange={(e) => {
    //             setSearchText(e.target.value);
    //           }}
    //           value={searchText}
    //         />
    //       </div>
    //       <Form.Group
    //         className="p-0"
    //         id="parentDatePicker"
    //         style={{
    //           flexBasis: "40%",
    //           display: "flex",
    //           gap: "0.5rem",
    //           minWidth: "250px",
    //           marginTop: "1rem"
    //         }}
    //       >
    //         <DatePicker
    //           dateFormat="dd/MM/yyyy"
    //           selected={startDate}
    //           onChange={(date) => setStartDate(date)}
    //           className="form-control"
    //           placeholderText="Start Date"
    //           style={{
    //             borderColor: "#ccc",
    //             padding: "0.5rem",
    //             height: "40px",
    //           }}
    //         />

    //         <DatePicker
    //           dateFormat="dd/MM/yyyy"
    //           selected={endDate}
    //           onChange={(date) => setEndDate(date)}
    //           className="form-control"
    //           placeholderText="End Date"
    //           style={{
    //             borderColor: "#ccc",
    //             padding: "0.5rem",
    //             height: "40px",
    //           }}
    //         />
    //       </Form.Group>

    //       <div className="d-flex align-items-center" id="searchButton" style={{ flexBasis: "10%", minWidth: "120px" }}>
    //         <Button
    //           className="rounded-50 btn btn-primary btn-lg"
    //           style={{
    //             fontSize: "0.7rem",
    //             height: "40px",
    //             padding: "0.5rem 1rem",
    //             width: "100%"
    //           }}
    //           // onClick={getAllVirtualPayments}
    //         >
    //           {"Search"}
    //         </Button>
    //       </div>

    //       <div
    //         className="d-flex align-items-center justify-content-center"
    //         style={{
    //           cursor: "pointer",
    //           border: "1px solid rgba(43, 193, 85, 1)",
    //           padding: "0.5rem 1rem",
    //           height: "40px",
    //           width: "11rem",
    //           backgroundColor: "rgba(43, 193, 85, 0.1)",
    //           color: "rgba(43, 193, 85, 1)",
    //           borderRadius: ".4rem",
    //           minWidth: "120px",
    //         }}
    //         // onClick={() => {
    //         //   ExportToExcel(
    //         //     `LEDGER_REPORT_${now()}`,
    //         //     excelMapping(data, columns)
    //         //   );
    //         // }}
    //       >
    //         <strong style={{ fontSize: "0.8rem" }}>Export to Excel</strong>
    //       </div>
    //     </div>
    //   </div>

    //   <div className=" ">
    //     <table
    //       className="table"
    //       style={{ fontFamily: "Poppins", border: "1px solid #bfbcbc" }}
    //     >
    //       <tr
    //         style={{ backgroundColor: "white", color: "black", border: "0.1px solid rgb(191 188 188)" }}
    //       >
    //         <th
    //           className="text-center bg-white text-black py-3"
    //         >
    //           {" "}
    //           {"Sr"}
    //         </th>
    //         {columns.map((heading, index) => (
    //           <th
    //             key={index}
    //             className="text-center bg-white text-black py-3"
    //           >
    //             {heading.text}
    //           </th>
    //         ))}
    //       </tr>
    //       {loading && (
    //         <div className="loader-container d-flex justify-content-center align-items-center">
    //           <div className="loader"></div>
    //         </div>
    //       )}
    //       {dataToDisplay.map((e, ind) => (
    //         <tr
    //           style={{ border: "0.1px solid rgb(191 188 188)" }}
    //           key={ind}>
    //           <td className="text-center align-middle" style={{ fontSize: ".8rem" , border:"none" }}>{ind + 1}</td>
    //           {columns.map((cell) => (
    //             <td style={{ border: "none" }}
    //               key={cell.dataField}
    //             // style={{ fontSize: ".8rem", color: cell.color, }}
    //             >
    //               {cell.dataField === "status" && (
    //                 <>
    //                   {(e[cell.dataField].toString().toLowerCase() ===
    //                     "refunded" ||
    //                     e[cell.dataField].toString().toLowerCase() ===
    //                     "refund") && (
    //                       <label
    //                         style={{
    //                           padding: "8px",
    //                           borderRadius: ".4rem",
    //                           backgroundColor: "rgba(0, 123, 255, 0.1)",
    //                           color: "rgba(0, 123, 255, 1)",
    //                           border: "1px solid rgba(0, 123, 255, 1)",
    //                           borderColor: "rgba(0, 123, 255, 1)",
    //                           fontSize: ".8rem",
    //                         }}
    //                         className="badge badge-warning"
    //                       >
    //                         {e[cell.dataField]}
    //                       </label>
    //                     )}
    //                   {e[cell.dataField] === "SettlementCompleted" && (
    //                     <label
    //                       style={{
    //                         padding: "8px",
    //                         border: "1px solid",
    //                         height: "2rem",
    //                         backgroundColor: "rgba(43, 193, 85, 0.1)",
    //                         color: "rgba(43, 193, 85, 1)",
    //                         borderColor: "rgba(43, 193, 85, 1)",
    //                         borderRadius: ".4rem",
    //                         fontSize: ".8rem",
    //                       }}
    //                       className="badge badge-success"
    //                     >
    //                       {e[cell.dataField]}
    //                     </label>
    //                   )}
    //                   {(e[cell.dataField] === "Received" ||
    //                     e[cell.dataField] === "Accepted") && (
    //                       <label
    //                         style={{
    //                           padding: "8px",
    //                           borderRadius: ".4rem",
    //                           backgroundColor: "rgba(0, 123, 255, 0.1)",
    //                           color: "rgba(0, 123, 255, 1)",
    //                           border: "1px solid rgba(0, 123, 255, 1)",
    //                           borderColor: "rgba(0, 123, 255, 1)",
    //                           fontSize: ".8rem",
    //                         }}
    //                         className="badge badge-warning"
    //                       >
    //                         {e[cell.dataField]}
    //                       </label>
    //                     )}
    //                   {e[cell.dataField].toString().toLowerCase() ===
    //                     "failed" && (
    //                       <label
    //                         style={{
    //                           padding: "8px",
    //                           borderRadius: ".4rem",
    //                           backgroundColor: "rgba(0, 123, 255, 0.1)",
    //                           color: "rgba(0, 123, 255, 1)",
    //                           border: "1px solid rgba(0, 123, 255, 1)",
    //                           borderColor: "rgba(0, 123, 255, 1)",
    //                           fontSize: ".8rem",
    //                         }}
    //                         className="badge badge-danger"
    //                       >
    //                         Failed
    //                       </label>
    //                     )}
    //                   {e[cell.dataField] === "0" && (
    //                     <label
    //                       style={{
    //                         padding: "8px",
    //                         borderRadius: ".4rem",
    //                         backgroundColor: "rgba(0, 123, 255, 0.1)",
    //                         color: "rgba(0, 123, 255, 1)",
    //                         border: "1px solid rgba(0, 123, 255, 1)",
    //                         borderColor: "rgba(0, 123, 255, 1)",
    //                         fontSize: ".8rem",
    //                       }}
    //                       className="badge badge-warning"
    //                     >
    //                       Virtual
    //                     </label>
    //                   )}
    //                 </>
    //               )}
    //               {cell.dataField !== "status" && e[cell.dataField]}
    //             </td>
    //           ))}
    //         </tr>
    //       ))}
    //     </table>
    //   </div>
    // </div>
  );
};

// export const Pagination = ({
//   page,
//   limit,
//   callback,
//   total,
//   count,
//   callback2,
// }) => {
//   console.table({ page, limit, callback });
//   return (
//     <div
//       className="row"
//       style={{
//         display: "flex",
//         alignItems: "center",
//         width: "100%",
//         margin: "10px 0px",
//         justifyContent: "space-between",
//       }}
//     >
//       <div
//         className="d-flex"
//         style={{
//           // border: "1px solid #33334d",
//           borderRadius: "4px",
//           marginTop: "5px",
//           overflow: "hidden",
//         }}
//       >
//         <select
//           className="form-control"
//           style={{ border: "none", outline: "none" }}
//           aria-label="offset"
//           onChange={(e) => {
//             callback2(e.target.value);
//           }}
//         >
//           {offsetArr.map((offset) => (
//             <option value={offset}>{offset}</option>
//           ))}
//         </select>
//         {page - 2 > 0 && (
//           <Button
//             onClick={() => {
//               callback(page - 1);
//             }}
//             variant="light"
//             style={{ border: "none", backgroundColor: "#f8f9fa" }}
//           >
//             Previous
//           </Button>
//         )}
//         {page - 1 > 0 && (
//           <Button
//             onClick={() => {
//               callback(page - 1);
//             }}
//             variant="light"
//             style={{ border: "none", backgroundColor: "#f8f9fa" }}
//           >
//             {(page - 1).toString()}
//           </Button>
//         )}
//         <Button
//           variant="light"
//           style={{
//             backgroundColor: "#33334d",
//             color: "#fff",
//             borderRadius: "0px",
//           }}
//         >
//           {page.toString()}
//         </Button>
//         {page + 1 <= limit && (
//           <Button
//             onClick={() => {
//               callback(page + 1);
//             }}
//             variant="light"
//             style={{
//               border: "none",
//               backgroundColor: "#f8f9fa",
//               boxShadow: "none",
//             }}
//           >
//             Next
//           </Button>
//         )}
//       </div>
//       <div>
//         <strong style={{ color: "#33334d" }}>{`Showing ${count} of ${total} entries`}</strong>
//       </div>
//     </div>

//   );
// };