import React, { useState } from "react";
import ViewAllWebhooks from "./AllWebhooks";
import ViewAllIpList from "./AllIpList";
import AllApiDocs from "./AllApiDocs";

const Developer = () => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div
        style={{
            fontFamily: 'Arial, sans-serif',
            backgroundColor: '#f0f0f0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            margin: 0,
            width: "100%"
        }}
    >
        <div
            style={{
                backgroundColor: '#fff',
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                width: '100%',
                padding: '20px',
                // textAlign: 'center',
                marginTop: '0rem',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    // marginBottom: '20px',
                    marginTop:"0 rem"
                }}
            >
                <div
                    onClick={() => handleTabClick(0)}
                    style={{
                        flex: 1,
                        padding: '10px',
                        cursor: 'pointer',
                        backgroundColor: activeTab === 0 ? '#2c3e50' : '#ecf0f1', 
                        color: activeTab === 0 ? '#fff' : '#34495e', 
                        border: 'none',
                        fontSize: '16px',
                        outline: 'none',
                        transition: 'background-color 0.3s ease',
                        borderRadius: '4px',
                    }}
                >
                    Webhooks
                </div>
                <div
                    onClick={() => handleTabClick(1)}
                    style={{
                        flex: 1,
                        padding: '10px',
                        cursor: 'pointer',
                        backgroundColor: activeTab === 1 ? '#2c3e50' : '#ecf0f1', 
                        color: activeTab === 1 ? '#fff' : '#34495e',
                        border: 'none',
                        fontSize: '16px',
                        outline: 'none',
                        transition: 'background-color 0.3s ease',
                        borderRadius: '4px',
                    }}
                >
                    IP List
                </div>
                <div
                    onClick={() => handleTabClick(2)}
                    style={{
                        flex: 1,
                        padding: '10px',
                        cursor: 'pointer',
                        backgroundColor: activeTab === 2 ? '#2c3e50' : '#ecf0f1', 
                        color: activeTab === 2 ? '#fff' : '#34495e', 
                        border: 'none',
                        fontSize: '16px',
                        outline: 'none',
                        transition: 'background-color 0.3s ease',
                        borderRadius: '4px',
                    }}
                >
                    API Doc
                </div>
            </div>
            <div
                style={{
                    fontSize: '18px',
                    padding: '20px',
                    borderRadius: '10px',
                    backgroundColor: '#fafafa',
                }}
            >
                {activeTab === 0 && <ViewAllWebhooks status={'Webhooks'} />}
                {activeTab === 1 && <ViewAllIpList status={'IP List'} />}
                {activeTab === 2 && <AllApiDocs status={'Api Doc'} />}
            </div>
        </div>
    </div>
    
    );
};

export default Developer;
