import React, { useState } from "react";
import './settlement.css';
import SettlementPay from "./SettlementPay";
import MyAccount from "../dataTables/components/MyAccount";
import AllUPIIntentReport from "../dataTables/components/ALLUpiIntentReport";
import { Payout } from "../dataTables/components/Payout";

const Settlement = () => {
    // Initialize default active tab as 0 (Settlement tab)
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    console.log("Hiii");
    

    return (
        <div className="p-4" style={{ marginTop: "4rem" }}>
        <div
          style={{
            backgroundColor: '#fff',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            width: '100%',
            padding: '20px',
            textAlign: 'center',
          }}
        >
          {/* Tab Navigation */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              marginBottom: '20px',
            }}
          >
            <div
              onClick={() => handleTabClick(0)}
              style={{
                flex: 1,
                padding: '10px',
                cursor: 'pointer',
                backgroundColor: activeTab === 0 ? '#2c3e50' : '#f0f0f0',
                color: activeTab === 0 ? '#fff' : '#000',
                border: 'none',
                fontSize: '16px',
                outline: 'none',
                transition: 'background-color 0.3s ease',
                borderRadius: '4px',
              }}
            >
              Settlement
            </div>
            <div
              onClick={() => handleTabClick(1)}
              style={{
                flex: 1,
                padding: '10px',
                cursor: 'pointer',
                backgroundColor: activeTab === 1 ? '#2c3e50' : '#f0f0f0',
                color: activeTab === 1 ? '#fff' : '#000',
                border: 'none',
                fontSize: '16px',
                outline: 'none',
                transition: 'background-color 0.3s ease',
                borderRadius: '4px',
              }}
            >
              Pay-in Report
            </div>
            <div
              onClick={() => handleTabClick(2)}
              style={{
                flex: 1,
                padding: '10px',
                cursor: 'pointer',
                backgroundColor: activeTab === 2 ? '#2c3e50' : '#f0f0f0',
                color: activeTab === 2 ? '#fff' : '#000',
                border: 'none',
                fontSize: '16px',
                outline: 'none',
                transition: 'background-color 0.3s ease',
                borderRadius: '4px',
              }}
            >
              Pay-out Report
            </div>
          </div>
      
          {/* Tab Content */}
          <div
            style={{
              fontSize: '18px',
              padding: '20px',
              borderRadius: '10px',
              backgroundColor: '#fafafa',
            }}
          >
            {activeTab === 0 && <div><SettlementPay status={'SUCCESS'} /></div>}
            {activeTab === 1 && <div><AllUPIIntentReport status={'EXPIRED'} /></div>}
            {activeTab === 2 && <div><Payout status={'N'} /></div>}
          </div>
        </div>
      </div>
      
    );
};

export default Settlement;
