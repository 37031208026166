'use client';
import React, { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import {
  Box,
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Input,
  InputGroup,
  Tr,
  FormLabel,
  InputRightElement,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { Link } from '@chakra-ui/react';
import { Select } from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import Menu from 'components/menu/MainMenu';
import { AndroidLogo, AppleLogo, WindowsLogo } from 'components/icons/Icons';
// import axios from 'axios';
import './CreateClient.css'
import { RiEyeCloseLine } from "react-icons/ri";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import "./LoadRequest.css"
import { ReduxContext } from "contexts/redux/ReduxState";
// import "./Loader.css"
// import { useAuth } from "../context/AuthContext";
// import { ReduxContext } from "../../redux/ReduxStore";

const columnHelper = createColumnHelper();

export default function LoadRequest(props) {
  // const { user } = useContext(ReduxContext);
  const { user } = React.useContext(ReduxContext);
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = React.useState(false);
  const textColorSecondary = "gray.400";
  const [loading, setLoading] = useState(false);
  const [bankOption, setBankOption] = useState([]);
  const [selectedBank, setSelectedBank] = useState({ _id: "", name: "" });
  const [amount, SetAmount] = useState("");
  const [transactionID, SetTransactionID] = useState("");
  const [date, setDate] = useState("");
  const [type, setType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [remark, setRemark] = useState("");
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const iconColor = useColorModeValue('secondaryGray.500', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  //Date Formatter
  const dateFormatter = (d) => {
    const date = new Date(d)
    return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
      }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const AddLoadRequest = async () => {
    setLoading(true);
    const rawData = {
      merchantId: user?.merchantId,
      // merchantId: "2",
      type: "1",
      amount: amount,
      description: remark,
      priority: "1",
      userType: "2",
      bnkid: selectedBank._id,
      utrno: transactionID,
      paymode: type,
      paySlip: "img",
      payDate: dateFormatter(date),
    };

    try {
      const response = await axios.post(
        `https://bumppy.in/payin/api/sdkClientLoadRequestSubmit.php`,
        rawData
      );

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.msg,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Load Request Failed",
          text: response.msg,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Load Request Failed",
        text: "Please try again",
      });
    } finally {
      setLoading(false); // Set loading to false when API call completes (success or failure)
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    AddLoadRequest();
  };

  const Banklist = async () => {
    const rawData = {
      // merchantId: user?.merchantId,
      merchantId:"2",
    };

    try {
      const response = await axios.post(
        `https://bumppy.in/payin/api/sdkClientLoadRequestBanks.php`,
        rawData
      );

      if (response.data.status === "00") {
        console.log(response.data.data);
        setBankOption(response.data.data);
      } else {
      }
    } catch (error) {
      console.error("Error fetching report data:", error);
    }
  };

  // Call the API on page load
  useEffect(() => {
    Banklist();
  }, []);

  const handleSelectBank = (e) => {
    const selectedBankValue = e.target.value;
    const selectedBankName = e.target.options[e.target.selectedIndex].text;

    setSelectedBank({ _id: selectedBankValue, name: selectedBankName });
  };
  return (
    <>
<Card
  flexDirection="column"
  w="100%"
  px="0px"
  overflowX={{ sm: 'scroll', lg: 'hidden' }}
>
  <Box>
    <div className="full-width-container"
    style={{marginRight:"25rem"}}>
      <div className="row">
        <div className="col-12 col-lg-6 offset-lg-3">
          <form onSubmit={handleSubmit}>
            <div className="form-title"> Add Load Request</div>

            <div className="row">
              <div className="col-12 col-lg-6 mb-3">
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Amount<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  value={amount}
                  onChange={(e) => SetAmount(e.target.value)}
                  type="text"
                  placeholder="Amount"
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                />
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Transaction ID<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="text"
                  value={transactionID}
                  placeholder="Transaction ID"
                  onChange={(e) => SetTransactionID(e.target.value)}
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-lg-6 mb-3">
                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                >
                  Select Bank<Text color={brandStars}>*</Text>
                </FormLabel>
                <InputGroup size="md">
                  <Select
                    isRequired={true}
                    fontSize="sm"
                    mb="24px"
                    size="lg"
                    value={selectedBank._id}
                    onChange={handleSelectBank}
                    variant="auth"
                  >
                   <option value="">Select Bank</option>
                  {bankOption.map((bank) => (
                    <option key={bank._id} value={bank.bankId}>
                      {bank.bank_name}
                    </option>
                  ))}
                  </Select>
                </InputGroup>
              </div>

              <div className="col-12 col-lg-6 mb-3">
                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                >
                  Type<Text color={brandStars}>*</Text>
                </FormLabel>
                <InputGroup size="md">
                <Select
                    isRequired={true}
                    fontSize="sm"
                    mb="24px"
                    size="lg"
                    placeholder="Select Type"
                    variant="auth"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                     <option value="3">Cash Deposit</option>
                  <option value="2">UPI/IMPS</option>
                  <option value="5">NEFT/RTGS</option>
                  <option value="9">CDM</option>
                  <option value="8">Fund Transfer</option>
                  <option value="10">CHEQUE</option>
                  </Select>
                </InputGroup>
              </div>
            </div>

            <div className="row">
              {/* Add the calendar here */}
              <div className="col-12 col-lg-6 mb-3">
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Date<Text color={brandStars}>*</Text>
                </FormLabel>
                {/* Calendar Date Picker */}
                <input
                  type="date"
                  className="form-control"
                  value={date}
                  placeholder="Enter date"
                  onChange={(e) => setDate(e.target.value)}
                  required
                  style={{
                    padding: "10px",
                    borderRadius: "5px",
                    borderColor: "#ced4da", 
                  }}
                />
              </div>

              <div className="col-12 col-lg-6 mb-3">
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Remarks<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="text"
                  value={remark}
                  placeholder="Enter Remarks"
                  onChange={(e) => setRemark(e.target.value)}
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                />
              </div>
            </div>

            <div className="button-container row">
              <button
                className="col btn btn-create rounded-2"
                style={{ color: "white", background: "#161D6F" }}
              >
                Submit
              </button>
              <button
                className="col btn btn-clear rounded-2"
                style={{ color: "white", background: "#C80036" }}
              >
                Clear Form
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </Box>
</Card>



      {/* <div
      className="p-0 p-lg-5"
      style={{
        backgroundColor: "#F1EFEF",
        fontFamily: "poppins",
        height: "93vh",
      }}
    >
      <div
        className="full-width-container text-dark bg-light p-3 m-3"
        style={{
          border: "0px solid #000000",
          borderRadius: "15px",
        }}
      >
        <div className="row p-0 p-lg-5">
          <div className="col-3"></div>
          <div
            className="col-12 p-3 p-lg-4 col-lg-6 bg-white shadow"
            style={{ borderRadius: "10px" }}
          >
            <form onSubmit={handleSubmit} className="row">
              <div className="mb-3 col-12 d-flex align-items-center justify-content-center text-center">
                <div
                  style={{
                    fontSize: "32px",
                    fontWeight: "bold",
                    fontFamily: "poppins",
                    color: "#33334d",
                  }}
                >
                  Add Load Request
                </div>
              </div>

              <div className="mb-3 col-12 col-lg-6">
                <label className="form-label">Amount</label>
                <input
                  type="number"
                  className="form-control"
                  value={amount}
                  placeholder="Enter Amount"
                  onChange={(e) => SetAmount(e.target.value)}
                  required
                  style={{
                    padding: "10px",
                    borderRadius: "5px",
                    borderColor: "#ced4da",
                  }}
                />
              </div>
              <div className="mb-3 col-12 col-lg-6">
                <label className="form-label">Transaction ID</label>
                <input
                  type="number"
                  className="form-control"
                  value={transactionID}
                  placeholder="Enter Transaction ID"
                  onChange={(e) => SetTransactionID(e.target.value)}
                  required
                  style={{
                    padding: "10px",
                    borderRadius: "5px",
                    borderColor: "#ced4da",
                  }}
                />
              </div>
              <div className="mb-3 col-12 col-lg-6">
                <label className="form-label">Select Bank</label>
                <select
                  className="form-control"
                  value={selectedBank._id}
                  onChange={handleSelectBank}
                  required
                  style={{
                    padding: "10px",
                    borderRadius: "5px",
                    borderColor: "#ced4da",
                  }}
                >
                  <option value="">Select Bank</option>
                  {bankOption.map((bank) => (
                    <option key={bank._id} value={bank.bankId}>
                      {bank.bank_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3 col-12 col-lg-6">
                <label className="form-label">Type</label>
                <select
                  className="form-control"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  required
                  style={{
                    padding: "10px",
                    borderRadius: "5px",
                    borderColor: "#ced4da",
                  }}
                >
                  <option value="3">Cash Deposit</option>
                  <option value="2">UPI/IMPS</option>
                  <option value="5">NEFT/RTGS</option>
                  <option value="9">CDM</option>
                  <option value="8">Fund Transfer</option>
                  <option value="10">CHEQUE</option>
                </select>
              </div>
              <div className="mb-3 col-12 col-lg-6">
                <label className="form-label">Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={date}
                  placeholder="Enter date"
                  onChange={(e) => setDate(e.target.value)}
                  required
                  style={{
                    padding: "10px",
                    borderRadius: "5px",
                    borderColor: "#ced4da",
                  }}
                />
              </div>
              <div className="mb-3 col-12 col-lg-6">
                <label className="form-label">Remarks</label>
                <input
                  type="text"
                  className="form-control"
                  value={remark}
                  placeholder="Enter Remarks"
                  onChange={(e) => setRemark(e.target.value)}
                  required
                  style={{
                    padding: "10px",
                    borderRadius: "5px",
                    borderColor: "#ced4da",
                  }}
                />
              </div>
              <div className="mt-4 col-12 d-flex justify-content-center">
                <button
                  className="btn btn-lg btn-block text-white bg-success"
                  style={{
                    width: "30%",
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
          <div className="col-3"></div>
        </div>
      </div>
    </div> */}
    </>
  );
};

