'use client';

import { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import { Link } from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import Menu from 'components/menu/MainMenu';
import { AndroidLogo, AppleLogo, WindowsLogo } from 'components/icons/Icons';
import axios from 'axios';
import * as React from 'react';
import { Badge, Button, Modal } from 'react-bootstrap';
import Loader from '../Loader';
import { ReduxContext } from 'contexts/redux/ReduxState';
import { Pagination } from 'components/utils/Pagination';
// import { set } from 'react-datepicker/dist/date_utils';

const columnHelper = createColumnHelper();

export default function ClientLedger(props) {
  const { user } = React.useContext(ReduxContext);
  const [transactionLoaderState, setTransactionLoaderState] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reportData, setReportData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [type, setType] = useState('1');
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [offset, setOffset] = useState(10);
  const [total, setTotal] = useState(0);
  const [pages, setPages] = useState(0);
  const [ledger, setLedger] = useState([]);
  const [balanceValues, setBalanceValues] = useState('');
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [error, setError] = useState(null);
  const [allMerchantList, setallMerchantList] = useState([]);
  const [sorting, setSorting] = useState([]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const iconColor = useColorModeValue('secondaryGray.500', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  // let defaultData = tableData;

  useEffect(() => {
    setPages(Math.floor((ledger.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(ledger.length);
    const filteredData = ledger.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [ledger]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + 999) / 1000));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = 999;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage, offset]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  useEffect(() => {
    setPages(Math.floor((ledger.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(ledger.length);
    const filteredData = ledger.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [ledger]);

  const GetMerchantLedgerReport = async () => {
    const rawData = {
      merchant_id: user?.merchantId,
    };

    try {
      setTransactionLoaderState(true);
      const response = await axios.post(
        `https://payinnew.remittpe.com/api/Intent/GetMerchantLedgerReport`,
        rawData,
      );

      if (response.status === 200) {
        if (response.data.status == '0') {
          setData(response.data.data);
        }
      } else {
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.error('Error fetching report data:', error);
    } finally {
      setTransactionLoaderState(false);
    }
    // setData(data.data)
  };
  useEffect(() => {
    GetMerchantLedgerReport();
  }, []);

  // const [data, setData] = React.useState(() => [...defaultData]);

  const headers = [
    { id: 'id', label: 'ID' },
    { id: 'fk_user_id', label: 'Merchant Id' },
    { id: 'payin_amount', label: 'Business Name' },
    { id: 'payin_charge', label: 'Payment API Balance' },
    { id: 'payin_gst', label: 'OnBoarding Date' },
    { id: 'settlement_time', label: 'OnBoarding Date' },
    { id: 'status', label: 'Status' },
    // { id: 'Action', label: 'Action' },
  ];

  return transactionLoaderState ? (
    <Loader />
  ) : (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
      style={{ marginTop: '5rem' }}
    >
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
          className="mt-4"
        >
          Client Ledger Report
        </Text>
      </Flex>
      <Box>
        <Table variant="simple" color="gray.500" mb="24px" mt="12px">
          <Thead>
            <Tr>
              {headers.map((header) => (
                <Th
                  key={header.id}
                  pe="10px"
                  borderColor={borderColor}
                  cursor="pointer"
                >
                  <Flex
                    justifyContent="space-between"
                    align="center"
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color="gray.400"
                  >
                    {header.label}
                  </Flex>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {dataToDisplay.map((item, index) => (
              <Tr
                key={item._id}
                style={{
                  border: 'none',
                  height: '3rem',
                  backgroundColor: 'white',
                  color: 'black',
                }}
              >
                <Td
                  style={{ border: 'none' }}
                  className="text-center align-middle"
                  fontSize={{ sm: '10px', lg: '13px' }}
                >
                  {item.id}
                </Td>
                <Td
                  style={{ border: 'none' }}
                  className="text-center align-middle"
                  fontSize={{ sm: '10px', lg: '13px' }}
                >
                  {item.fk_user_id}
                </Td>
                <Td
                  style={{ border: 'none' }}
                  className="text-center align-middle"
                  fontSize={{ sm: '10px', lg: '13px' }}
                >
                  {item.payin_amount || 'NA'}
                </Td>
                <Td
                  style={{ border: 'none' }}
                  className="text-center align-middle"
                  fontSize={{ sm: '10px', lg: '13px' }}
                >
                  {item.payin_charge}
                </Td>{' '}
                <Td
                  style={{ border: 'none' }}
                  className="text-center align-middle"
                  fontSize={{ sm: '10px', lg: '13px' }}
                >
                  {item.payin_gst}
                </Td>{' '}
                <Td
                  style={{ border: 'none' }}
                  className="text-center align-middle"
                  fontSize={{ sm: '10px', lg: '13px' }}
                >
                  {item.settlement_time}
                </Td>
                <Td
                  style={{ border: 'none' }}
                  className="text-center align-middle"
                  fontSize={{ sm: '10px', lg: '13px' }}
                >
                  {item.status === 'UNSETTLED' ? (
                    <button
                      style={{
                        backgroundColor: 'orange',
                        color: 'white',
                        border: 'none',
                        padding: '5px 10px',
                        borderRadius: '5px',
                        width: '100px', // Set a fixed width
                      }}
                    >
                      {item.status}
                    </button>
                  ) : item.status === 'SETTLED' ? (
                    <button
                      style={{
                        backgroundColor: 'green',
                        color: 'white',
                        border: 'none',
                        padding: '5px 10px',
                        borderRadius: '5px',
                        width: '100px',
                      }}
                    >
                      {item.status}
                    </button>
                  ) : (
                    <span>{item.status}</span>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Pagination
          page={currentPage}
          limit={pages}
          callback={(page) => {
            setCurrentPage(page);
          }}
          count={dataToDisplay.length}
          total={total}
          callback2={(offsetValue) => {
            setOffset(offsetValue);
          }}
        />
      </Box>
    </Card>
  );
}
