import React, { useState, useEffect, useContext } from 'react';
import { Button, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
// import { excelMapping, ExportToExcel, now } from "../../utils/utilFuctions";
// import { offsetArr } from "../../utils/CommonConfig";

import {
  Box,
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
// import Select from "react-select";
import { Select } from '@chakra-ui/react';
import { Pagination } from 'components/utils/Pagination';
import { ExportToExcel } from 'components/utils/utilFunctions';
import { now } from 'components/utils/utilFunctions';
// import { ReduxContext } from "../../redux/ReduxStore";

const options = [
  { label: 'Success', value: 'success' },
  { label: 'Failed', value: 'failed' },
  { label: 'Virtual', value: '0' },
  { label: 'Settlement Completed', value: 'settlementcompleted' },
  { label: 'Received', value: 'received' },
];

export const columns = [
  {
    dataField: 'merchantTransactionid',
    text: 'Merchant Txnt ID',
    color: '#586275',
  },
  {
    dataField: 'refid',
    text: 'Ref id',
    color: '#586275',
  },
  {
    dataField: 'utnno',
    text: 'UTR No',
    color: '#586275',
  },
  {
    dataField: 'transactiontype',
    text: 'Transaction type',
    color: '#586275',
  },
  {
    dataField: 'transactionAmount',
    text: 'Amount',
    color: '#586275',
  },
  {
    dataField: 'cgstAmount',
    text: 'CGST Amount',
    color: '#586275',
  },
  {
    dataField: 'sgstAmount',
    text: 'SGST Amount',
    color: '#586275',
  },
  {
    dataField: 'chargesAmount',
    text: 'Charges',
    color: '#586275',
  },
  {
    dataField: 'debitAmount',
    text: 'Debit',
    color: '#586275',
  },
  {
    dataField: 'creditAmount',
    text: 'Credit',
    color: '#586275',
  },
  {
    dataField: 'balance',
    text: 'Balance',
    color: '#586275',
  },
  {
    dataField: 'status',
    text: 'Status',
    color: '#000',
  },

  {
    dataField: 'createdOn',
    text: 'Created On',
    color: '#586275',
  },
];

const Transactions = ({ setDetails }) => {
  // const {setTransactionLoaderState} = useContext(ReduxContext);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const iconColor = useColorModeValue('secondaryGray.500', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(10);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const [ledger, setLedger] = useState([]);

  const dateFormatter = (date = new Date()) => {
    const mm = date.getMonth() + 1;
    return `${date.getFullYear()}-${mm > 9 ? mm : '0' + mm}-${
      date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
    }`;
  };
  const getAllVirtualPayments = async () => {
    const accessToken = sessionStorage.getItem('accessToken');
    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${accessToken}`);
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append(
      'Cookie',
      '.AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iwOgwEj6EBN0jpchgFhuiV_O6Tcuf6QLM3cjarWosB3o17JTbrLuvcOmIJGLw0OwV-JJr2mhON-MIsfNGVplhmoJMIoQQrIyv6dzlCW8_eOvZw0ahT_icJcGsoHfR-1FLppZoY2SWzX8NUi9aabidVqbnsJjig4BpI2oPpnu73FH5iMh2Kfuz2xWcPSdx08C7D_6Ygts4I9XapyCvm0OVKguJ3fWPFHBgoY-PSnQ4KNzeh-OI4TzaffhkWCBwgBLtA6ybohQ1cqY5c2Y2EPo-sgAft8xvCDg9pibGVqcUdtEkqHj-BKKBdLijXEW-P7Q9e-T-wL07LWdnOnMPF9YCns79c8kBgyHTiVi82lFdqqIZtzZnhJsZoVhf9vsGaD37-6zhe4sDOkUJf4WCLur6gGQB8LQrfK9RZg30X0OvMv7ZCOtC4SKxgXiyk44NtkqY9oP_cVSb00F_E80WkQhk1A4nUfPalYivPP3EPF45TtK2hlhfdvdw_G1rMLrDiTbaIj-yvW5831ualRbsVUhVSHiUZzLTshMjUg-r_o5FNk4OSR2EyJm2l1WGwLdtZccqzvuyG_UzV9XiIYA658Nqy0iQX-ckKK4yR0yLPGXkmyx1MzMh9RjVPJwsRmhnIKqPE4eOFQNdJDYowJQhEQIMZV',
    );

    var raw = JSON.stringify({
      fromDate: dateFormatter(startDate),
      toDate: dateFormatter(endDate),
      pageIndex: currentPage,
      pageSize: 10000000,
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    try {
      setLoading(true);

      const res = await fetch(
        'https://apitranxt.paynnow.com/api/Payments/GetTransactionsall',
        requestOptions,
      );
      console.log('response', res);
      if (res.status == 401) {
        // removeMyAccountInfo();
      } else if (res.ok) {
        setLoading(false);

        const data = await res.json();
        console.log(data);
        if (data && data.length > 0) {
          const dataLoad = data.map((acc) => {
            return {
              ...acc,
              upi: `${acc.beneAccountNo}@yesbankltd`,
              date: new Date(acc.createdOn),
            };
          });
          dataLoad.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
          });
          console.log(dataLoad);
          setLedger(dataLoad);
          setDataToDisplay(dataLoad);
        }
      }
      // setPayments(JSON.parse(body).data);
    } catch (error) {
      setLoading(false);

      console.log('401 catched', error);
    }
  };

  const openInNewTab = () => {
    const newWindow = window.open(
      `/pages/print?id=090422191784660&type=DMT`,
      '',
      "height=600,width=800'",
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage, offset]);

  useEffect(() => {
    setPages(Math.floor((ledger.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(ledger.length);
    const filteredData = ledger.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [ledger]);

  // did mount
  useEffect(() => {
    getAllVirtualPayments();
  }, []);

  console.log(ledger);

  useEffect(() => {
    if (selectedStatus) {
      const filteredData = ledger.filter((e) => {
        return e.status.toString().toLowerCase() == selectedStatus.value;
      });
      setDataToDisplay(filteredData);
    }
  }, [selectedStatus]);

  const headers = [
    { id: 'id', label: 'Sr' },
    { id: 'RefId', label: 'Ref Id' },
    { id: 'RRN', label: 'RRN' },
    { id: 'Amount', label: 'Amount' },
    { id: 'Charges', label: 'Charges' },
    { id: 'GST', label: 'GST' },
    { id: 'Date', label: 'Date' },
    { id: 'Status', label: 'Status' },
    { id: 'Action', label: 'Action' },
  ];

  return (
    <div style={{ background: 'white' }} className="w-100">
      <div
        className="w-100 row m-2 p-0 pt-4 align-items-center"
        id="parentFilters"
      >
        <div
          className="m-0 p-0 pl-2 flex-grow-1 d-flex"
          style={{
            fontFamily: 'Poppins',
            fontSize: '28px',
            fontWeight: '600',
            lineHeight: '48px',
            textAlign: 'left',
            background: '#FFFFFF',
            color: '#33334d',
            minWidth: '150px',
          }}
        >
          Transactions
        </div>

        <div
          id="filters"
          style={{ gap: '0.5rem', display: 'flex', alignItems: 'center' }}
        >
          <div
            id="searchBarStyle"
            className="m-1 p-0"
            style={{ flexBasis: '20%', minWidth: '150px' }}
          >
            <input
              type="text"
              className="form-control"
              placeholder="Search text..."
              style={{
                border: '1px solid #bfbcbc',
                padding: '0.5rem',
                height: '40px',
                width: '100%',
              }}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              value={searchText}
            />
          </div>

          <div
            className="col p-0"
            style={{ flexBasis: '20%', minWidth: '120px' }}
          >
            <Select
              options={options}
              value={selectedStatus}
              onChange={(e) => {
                setSelectedStatus(e);
              }}
              style={{
                width: '100%',
                height: '40px',
                border: '1px solid #bfbcbc',
                padding: '0.5rem',
              }}
            />
          </div>

          <Form.Group
            className="p-0"
            id="parentDatePicker"
            style={{
              flexBasis: '40%',
              display: 'flex',
              gap: '0.5rem',
              minWidth: '250px',
              marginTop: '1rem',
            }}
          >
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="form-control"
              placeholderText="Start Date"
              style={{
                borderColor: '#ccc',
                padding: '0.5rem',
                height: '40px',
              }}
            />

            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className="form-control"
              placeholderText="End Date"
              style={{
                borderColor: '#ccc',
                padding: '0.5rem',
                height: '40px',
              }}
            />
          </Form.Group>

          <div
            className="d-flex align-items-center"
            id="searchButton"
            style={{ flexBasis: '10%', minWidth: '120px' }}
          >
            <Button
              className="rounded-50 btn btn-primary btn-lg"
              style={{
                fontSize: '0.7rem',
                height: '40px',
                padding: '0.5rem 1rem',
                width: '100%',
              }}
              onClick={getAllVirtualPayments}
            >
              {'Search'}
            </Button>
          </div>

          <div
            className="d-flex align-items-center justify-content-center mr-2"
            style={{
              cursor: 'pointer',
              border: '1px solid rgba(43, 193, 85, 1)',
              padding: '0.5rem 1rem',
              height: '40px',
              width: '8rem',
              backgroundColor: 'rgba(43, 193, 85, 0.1)',
              color: 'rgba(43, 193, 85, 1)',
              borderRadius: '.4rem',
              minWidth: '120px',
            }}
            onClick={() => {
              ExportToExcel(
                `Transactions_${now()}`,
                ledger
              );
            }}
          >
            <strong style={{ fontSize: '0.8rem' }}>Export to Excel</strong>
          </div>
        </div>
      </div>

      <div className="">
        <table
          className="table"
          style={{ fontFamily: 'Poppins', border: '1px solid #bfbcbc' }}
        >
          <tr
            style={{
              backgroundColor: 'white',
              color: 'black',
              border: '0.1px solid rgb(191 188 188)',
            }}
          >
            <th className="text-center bg-white text-black py-3"> {'Sr'}</th>
            {columns.map((heading, index) => (
              <th key={index} className="text-center bg-white text-black py-3">
                {heading.text}
              </th>
            ))}
          </tr>
          {loading && (
            <div className="loader-container d-flex justify-content-center align-items-center">
              <div className="loader"></div>
            </div>
          )}
          {dataToDisplay.map((e, ind) => (
            <tr style={{ border: '0.1px solid rgb(191 188 188)' }} key={ind}>
              <td
                className="text-center align-middle"
                style={{ fontSize: '.8rem', border: 'none' }}
              >
                {ind + 1}
              </td>
              {columns.map((cell) => (
                <td key={cell.dataField} style={{ border: 'none' }}>
                  {cell.dataField === 'status' && (
                    <>
                      {e[cell.dataField].toString().toLowerCase() ===
                        'refunded' ||
                      e[cell.dataField].toString().toLowerCase() ===
                        'refund' ? (
                        <label
                          style={{
                            padding: '8px',
                            borderRadius: '.4rem',
                            backgroundColor: 'rgba(0, 123, 255, 0.1)',
                            color: 'rgba(0, 123, 255, 1)',
                            border: '1px solid rgba(0, 123, 255, 1)',
                            borderColor: 'rgba(0, 123, 255, 1)',
                            fontSize: '0.8rem',
                          }}
                          className="badge badge-warning"
                        >
                          {e[cell.dataField]}
                        </label>
                      ) : e[cell.dataField].toString().toLowerCase() ==
                          'settlementcompleted' ||
                        e[cell.dataField].toString().toLowerCase() ==
                          'success' ? (
                        <label
                          style={{
                            padding: '8px',
                            border: '1px solid',
                            height: '2rem',
                            backgroundColor: 'rgba(43, 193, 85, 0.1)',
                            color: 'rgba(43, 193, 85, 1)',
                            borderColor: 'rgba(43, 193, 85, 1)',
                            borderRadius: '.4rem',
                            fontSize: '.8rem',
                          }}
                          className="badge badge-success"
                        >
                          {e[cell.dataField]}
                        </label>
                      ) : e[cell.dataField] === 'Received' ||
                        e[cell.dataField] === 'Accepted' ? (
                        <label
                          style={{
                            padding: '8px',
                            borderRadius: '.4rem',
                            backgroundColor: 'rgba(255, 238, 0, 0.1)',
                            color: 'rgba(255, 196, 0, 1)',
                            border: '1px solid rgba(255, 238, 0, 1)',
                            borderColor: 'rgba(255, 238, 0, 1)',
                            fontSize: '0.8rem',
                          }}
                          className="badge badge-warning"
                        >
                          {e[cell.dataField]}
                        </label>
                      ) : e[cell.dataField].toString().toLowerCase() ===
                        'failed' ? (
                        <label
                          style={{
                            padding: '8px',
                            borderRadius: '.4rem',
                            backgroundColor: 'rgba(255, 0, 0, 0.1)',
                            color: 'rgba(255, 0, 0, 1)',
                            border: '1px solid rgba(255, 0, 0, 1)',
                            borderColor: 'rgba(255, 0, 0, 1)',
                            fontSize: '0.8rem',
                          }}
                          className="badge badge-danger"
                        >
                          Failed
                        </label>
                      ) : e[cell.dataField] === '0' ? (
                        <label
                          style={{
                            padding: '8px',
                            borderRadius: '.4rem',
                            backgroundColor: 'rgba(0, 123, 255, 0.1)',
                            color: 'rgba(0, 123, 255, 1)',
                            border: '1px solid rgba(0, 123, 255, 1)',
                            borderColor: 'rgba(0, 123, 255, 1)',
                            fontSize: '0.8rem',
                            textAlign: 'center',
                          }}
                          className="badge badge-warning "
                        >
                          Virtual
                        </label>
                      ) : (
                        <label
                          style={{
                            padding: '8px',
                            borderRadius: '.4rem',
                            backgroundColor: 'rgba(255, 238, 0, 0.1)',
                            color: 'rgba(255, 196, 0, 1)',
                            border: '1px solid rgba(255, 238, 0, 1)',
                            borderColor: 'rgba(255, 238, 0, 1)',
                            fontSize: '0.8rem',
                            textAlign: 'center',
                          }}
                          className="badge badge-warning "
                        >
                          {e[cell.dataField]}
                        </label>
                      )}
                    </>
                  )}
                  {cell.dataField !== 'status' && e[cell.dataField]}
                </td>
              ))}
            </tr>
          ))}
        </table>
        {/* {ledger.length <= 0 && <NoDataFound />} */}
      </div>
      {/* <Pagination
            page={currentPage}
            limit={pages}
            callback={(page) => {
              setCurrentPage(page);
            }}
            count={dataToDisplay.length}
            total={total}
            callback2={(offsetValue) => {
              setOffset(offsetValue);
            }}
          /> */}
    </div>
  );
};
export const Payin = ({ setDetails }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(10);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  // const { removeMyAccountInfo, setTransactionLoaderState } = useContext(ReduxContext);

  const [ledger, setLedger] = useState([]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() : '0' + date.getMonth()
    }-${date.getDate() > 9 ? date.getDate() : '0' + date.getDate()}`;
  };
  const getAllVirtualPayments = async () => {
    const accessToken = sessionStorage.getItem('accessToken');
    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${accessToken}`);
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append(
      'Cookie',
      '.AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iwOgwEj6EBN0jpchgFhuiV_O6Tcuf6QLM3cjarWosB3o17JTbrLuvcOmIJGLw0OwV-JJr2mhON-MIsfNGVplhmoJMIoQQrIyv6dzlCW8_eOvZw0ahT_icJcGsoHfR-1FLppZoY2SWzX8NUi9aabidVqbnsJjig4BpI2oPpnu73FH5iMh2Kfuz2xWcPSdx08C7D_6Ygts4I9XapyCvm0OVKguJ3fWPFHBgoY-PSnQ4KNzeh-OI4TzaffhkWCBwgBLtA6ybohQ1cqY5c2Y2EPo-sgAft8xvCDg9pibGVqcUdtEkqHj-BKKBdLijXEW-P7Q9e-T-wL07LWdnOnMPF9YCns79c8kBgyHTiVi82lFdqqIZtzZnhJsZoVhf9vsGaD37-6zhe4sDOkUJf4WCLur6gGQB8LQrfK9RZg30X0OvMv7ZCOtC4SKxgXiyk44NtkqY9oP_cVSb00F_E80WkQhk1A4nUfPalYivPP3EPF45TtK2hlhfdvdw_G1rMLrDiTbaIj-yvW5831ualRbsVUhVSHiUZzLTshMjUg-r_o5FNk4OSR2EyJm2l1WGwLdtZccqzvuyG_UzV9XiIYA658Nqy0iQX-ckKK4yR0yLPGXkmyx1MzMh9RjVPJwsRmhnIKqPE4eOFQNdJDYowJQhEQIMZV',
    );

    var raw = JSON.stringify({
      pageIndex: currentPage,
      pageSize: 10000000,
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };
    try {
      // setTransactionLoaderState(true)
      const res = await fetch(
        'https://apitranxt.paynnow.com/api/Payments/GetTransactionsall',
        requestOptions,
      );
      console.log('response', res);
      if (res.status == 401) {
        //   removeMyAccountInfo();
      } else if (res.ok) {
        const data = await res.json();
        console.log(data);
        if (data && data.length > 0) {
          const dataLoad = data
            .filter((acc) => {
              return Number(acc.creditAmount) != 0;
            })
            .map((acc) => {
              return {
                ...acc,
                upi: `${acc.beneAccountNo}@yesbankltd`,
                date: new Date(acc.createdOn),
              };
            });
          dataLoad.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
          });
          console.log(dataLoad);
          setLedger(dataLoad);
          setDataToDisplay(dataLoad);
        }
      }
      // setTransactionLoaderState(false)
      // setPayments(JSON.parse(body).data);
    } catch (error) {
      // setTransactionLoaderState(false)
      console.log('401 catched', error);
    }
  };

  const openInNewTab = () => {
    const newWindow = window.open(
      `/pages/print?id=090422191784660&type=DMT`,
      '',
      "height=600,width=800'",
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + 999) / 1000));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = 999;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * 1000;
    const endIdx = currentPage * 1000 - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  useEffect(() => {
    // getAllTransactions();
  }, [currentPage]);

  useEffect(() => {
    setPages(Math.floor((ledger.length + 999) / 1000));
    const startIdx = 0;
    const endIdx = 999;
    setTotal(ledger.length);
    const filteredData = ledger.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [ledger]);

  // did mount
  useEffect(() => {
    getAllVirtualPayments();
  }, []);
  console.log(ledger);
  return (
    <div div style={{ background: 'white' }}>
      <div
        style={{
          background: '#fff',
        }}
      >
        <div className="row">
          <div className="col-7 m-2">
            <div
              className="d-flex"
              style={{
                cursor: 'pointer',
                border: '1px solid',
                height: '2.5rem',
                width: '11rem',
                backgroundColor: 'rgba(43, 193, 85, 0.1)',
                color: 'rgba(43, 193, 85, 1)',
                bordercolor: 'rgba(43, 193, 85, 1)',
                borderRadius: '.4rem',
              }}
              // onClick={() => {
              //   ExportToExcel(
              //     `PayIn_Report_${now()}`,
              //     excelMapping(dataToDisplay, columns)
              //   );
              // }}
            >
              {/* <img alt=""
                src={require("../../assets/images/excel.png")}
                style={{ width: "1.6rem", marginLeft: "15px" }}
              /> */}
              <strong
                className="d-flex align-items-center"
                style={{ marginLeft: '2px' }}
              >
                Export to Excel
              </strong>
            </div>
          </div>

          <div className=" col m-2 daterange-picker">
            <Form.Group>
              <Form.Control
                type="text"
                className="form-control rounded-0"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Search"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
              />
            </Form.Group>
            <Form.Group style={{ marginLeft: '10px', width: '8rem' }}>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                className="form-control"
                placeholderText="Start Date"
                style={{ borderRadius: '0px' }}
              />
            </Form.Group>

            <Form.Group style={{ marginLeft: '10px', width: '8rem' }}>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                className="form-control"
                placeholderText="End Date"
                style={{ borderRadius: '0px' }}
              />
            </Form.Group>
            <div style={{ marginLeft: '10px' }}>
              {' '}
              <Button
                className=""
                style={{ borderRadius: '.4rem' }}
                variant="primary"
                onClick={() => {}}
              >
                {'Search'}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="m-0 p-0 card mx-3 center-block scroll-inner">
        <table
          className="dashboard-table-style"
          style={{ width: '100%', overflowX: 'auto' }}
        >
          <tr>
            <th
              className="text-center"
              style={{
                fontSize: '0.9rem',
                background: '#f5f6f8',
                color: '#586275',
              }}
            >
              {' '}
              {'Sr'}
            </th>
            {columns.map((heading, index) => (
              <th
                key={index}
                className="text-center"
                style={{
                  fontSize: '0.9rem',
                  background: '#f5f6f8',
                  color: '#586275',
                }}
              >
                {heading.text}
              </th>
            ))}
          </tr>
          {dataToDisplay.map((e, ind) => (
            <tr key={ind}>
              <td style={{ fontSize: '1rem' }}>{ind + 1}</td>
              {columns.map((cell) => (
                <td
                  key={cell.dataField}
                  style={{ fontSize: '.8rem', color: cell.color }}
                >
                  {cell.dataField === 'status' && (
                    <>
                      {(e[cell.dataField].toString().toLowerCase() ===
                        'refunded' ||
                        e[cell.dataField].toString().toLowerCase() ===
                          'refund') && (
                        <label
                          style={{
                            padding: '8px',
                            borderRadius: '.4rem',
                            backgroundColor: 'rgba(0, 123, 255, 0.1)',
                            color: 'rgba(0, 123, 255, 1)',
                            border: '1px solid rgba(0, 123, 255, 1)',
                            borderColor: 'rgba(0, 123, 255, 1)',
                            fontSize: '0.8rem',
                          }}
                          className="badge badge-warning"
                        >
                          {e[cell.dataField]}
                        </label>
                      )}
                      {e[cell.dataField] === 'SettlementCompleted' && (
                        <label
                          style={{
                            padding: '8px',
                            border: '1px solid',
                            height: '2rem',
                            backgroundColor: 'rgba(43, 193, 85, 0.1)',
                            color: 'rgba(43, 193, 85, 1)',
                            borderColor: 'rgba(43, 193, 85, 1)',
                            borderRadius: '.4rem',
                            fontSize: '.8rem',
                          }}
                          className="badge badge-success"
                        >
                          {e[cell.dataField]}
                        </label>
                      )}
                      {(e[cell.dataField] === 'Received' ||
                        e[cell.dataField] === 'Accepted') && (
                        <label
                          style={{
                            padding: '8px',
                            borderRadius: '.4rem',
                            backgroundColor: 'rgba(0, 123, 255, 0.1)',
                            color: 'rgba(0, 123, 255, 1)',
                            border: '1px solid rgba(0, 123, 255, 1)',
                            borderColor: 'rgba(0, 123, 255, 1)',
                            fontSize: '0.8rem',
                          }}
                          className="badge badge-warning"
                        >
                          {e[cell.dataField]}
                        </label>
                      )}
                      {e[cell.dataField].toString().toLowerCase() ===
                        'failed' && (
                        <label
                          style={{
                            padding: '8px',
                            borderRadius: '.4rem',
                            backgroundColor: 'rgba(0, 123, 255, 0.1)',
                            color: 'rgba(0, 123, 255, 1)',
                            border: '1px solid rgba(0, 123, 255, 1)',
                            borderColor: 'rgba(0, 123, 255, 1)',
                            fontSize: '0.8rem',
                          }}
                          className="badge badge-danger"
                        >
                          Failed
                        </label>
                      )}
                      {e[cell.dataField] === '0' && (
                        <label
                          style={{
                            padding: '8px',
                            borderRadius: '.4rem',
                            backgroundColor: 'rgba(0, 123, 255, 0.1)',
                            color: 'rgba(0, 123, 255, 1)',
                            border: '1px solid rgba(0, 123, 255, 1)',
                            borderColor: 'rgba(0, 123, 255, 1)',
                            fontSize: '0.8rem',
                          }}
                          className="badge badge-warning"
                        >
                          Virtual
                        </label>
                      )}
                    </>
                  )}
                  {cell.dataField !== 'status' && e[cell.dataField]}
                </td>
              ))}
            </tr>
          ))}
        </table>
      </div>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
        callback2={(offsetValue) => {
          setOffset(offsetValue);
        }}
      />
    </div>
  );
};

export const Payout = ({ setDetails }) => {
  // const { removeMyAccountInfo, setTransactionLoaderState } = useContext(ReduxContext);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const iconColor = useColorModeValue('secondaryGray.500', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(10);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const [ledger, setLedger] = useState([]);

  const headers = [
    { id: 'id', label: 'Sr' },
    { id: 'RefId', label: 'Ref Id' },
    { id: 'RRN', label: 'RRN' },
    { id: 'Amount', label: 'Amount' },
    { id: 'Charges', label: 'Charges' },
    { id: 'GST', label: 'GST' },
    { id: 'Date', label: 'Date' },
    { id: 'Status', label: 'Status' },
    { id: 'Action', label: 'Action' },
  ];

  const dateFormatter = (date = new Date()) => {
    const mm = date.getMonth() + 1;
    return `${date.getFullYear()}-${mm > 9 ? mm : '0' + mm}-${
      date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
    }`;
  };
  const getAllVirtualPayments = async () => {
    const accessToken = sessionStorage.getItem('accessToken');
    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${accessToken}`);
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append(
      'Cookie',
      '.AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iwOgwEj6EBN0jpchgFhuiV_O6Tcuf6QLM3cjarWosB3o17JTbrLuvcOmIJGLw0OwV-JJr2mhON-MIsfNGVplhmoJMIoQQrIyv6dzlCW8_eOvZw0ahT_icJcGsoHfR-1FLppZoY2SWzX8NUi9aabidVqbnsJjig4BpI2oPpnu73FH5iMh2Kfuz2xWcPSdx08C7D_6Ygts4I9XapyCvm0OVKguJ3fWPFHBgoY-PSnQ4KNzeh-OI4TzaffhkWCBwgBLtA6ybohQ1cqY5c2Y2EPo-sgAft8xvCDg9pibGVqcUdtEkqHj-BKKBdLijXEW-P7Q9e-T-wL07LWdnOnMPF9YCns79c8kBgyHTiVi82lFdqqIZtzZnhJsZoVhf9vsGaD37-6zhe4sDOkUJf4WCLur6gGQB8LQrfK9RZg30X0OvMv7ZCOtC4SKxgXiyk44NtkqY9oP_cVSb00F_E80WkQhk1A4nUfPalYivPP3EPF45TtK2hlhfdvdw_G1rMLrDiTbaIj-yvW5831ualRbsVUhVSHiUZzLTshMjUg-r_o5FNk4OSR2EyJm2l1WGwLdtZccqzvuyG_UzV9XiIYA658Nqy0iQX-ckKK4yR0yLPGXkmyx1MzMh9RjVPJwsRmhnIKqPE4eOFQNdJDYowJQhEQIMZV',
    );

    var raw = JSON.stringify({
      fromDate: dateFormatter(startDate),
      toDate: dateFormatter(endDate),
      pageIndex: currentPage,
      pageSize: 10000000,
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };
    try {
      setLoading(true);
      // setTransactionLoaderState(true)
      const res = await fetch(
        'https://apitranxt.paynnow.com/api/Payments/GetTransactionsall',
        requestOptions,
      );
      console.log('response', res);
      if (res.status == 401) {
        // removeMyAccountInfo();
      } else if (res.ok) {
        setLoading(false);

        const data = await res.json();
        console.log(data);
        if (data && data.length > 0) {
          const dataLoad = data

            .filter((acc) => {
              return Number(acc.debitAmount) != 0;
            })
            .map((acc) => {
              return {
                ...acc,
                upi: `${acc.beneAccountNo}@yesbankltd`,
                date: new Date(acc.createdOn),
              };
            });
          dataLoad.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
          });
          console.log(dataLoad);
          setLedger(dataLoad);
          setDataToDisplay(dataLoad);
        }
      }
      // setTransactionLoaderState(false)
      // setPayments(JSON.parse(body).data);
    } catch (error) {
      setLoading(false);

      // setTransactionLoaderState(false)
      console.log('401 catched', error);
    }
  };

  const openInNewTab = () => {
    const newWindow = window.open(
      `/pages/print?id=090422191784660&type=DMT`,
      '',
      "height=600,width=800'",
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage, offset]);

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * 1000;
    const endIdx = currentPage * 1000 - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  useEffect(() => {
    // getAllTransactions();
  }, [currentPage]);

  useEffect(() => {
    setPages(Math.floor((ledger.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(ledger.length);
    const filteredData = ledger.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [ledger]);

  // did mount
  useEffect(() => {
    getAllVirtualPayments();
  }, []);

  useEffect(() => {
    if (selectedStatus) {
      const filteredData = ledger.filter((e) => {
        return e.status.toString().toLowerCase() == selectedStatus.value;
      });
      setDataToDisplay(filteredData);
    }
  }, [selectedStatus]);

  console.log(ledger);
  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflow="visible"
      minHeight="400px"
    >
      <Box>
        <div
          id="filters"
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
            margin: '1rem 0',
          }}
        >
          <Flex
            px="25px"
            mb="8px"
            justifyContent="space-between"
            align="center"
            style={{ flexGrow: 1 }}
          >
            <Text
              color={textColor}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
              className="mt-4"
            >
              Pay-out Report
            </Text>
          </Flex>

          <Form.Group
            id="parentDatePicker"
            style={{ display: 'flex', gap: '1rem', width: '2rem', flexGrow: 1 }}
          >
            <DatePicker
              className="form-control"
              style={{
                borderColor: '#ddd',
                padding: '0.5rem',
                height: '40px',
                borderRadius: '5px',
                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
                flexGrow: 1,
              }}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />

            <DatePicker
              className="form-control"
              style={{
                borderColor: '#ddd',
                padding: '0.5rem',
                height: '40px',
                borderRadius: '5px',
                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
                flexGrow: 1,
              }}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </Form.Group>

          <div
            className="d-flex align-items-center"
            id="searchButton"
            style={{ minWidth: '120px' }}
          >
            <Button
              className="btn btn-primary btn-lg"
              style={{
                fontSize: '0.8rem',
                height: '40px',
                padding: '0.5rem 1rem',
                backgroundColor: 'rgb(22, 29, 111)',
                borderColor: 'none',
                color: '#fff',
                borderRadius: '5px',
                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
                width: '100%',
              }}
              onClick={getAllVirtualPayments}
            >
              Search
            </Button>
          </div>

          <div
            className="d-flex align-items-center justify-content-center "
            style={{
              cursor: 'pointer',
              border: '1px solid rgba(43, 193, 85, 1)',
              padding: '0.5rem 1rem',
              height: '40px',
              backgroundColor: 'rgba(43, 193, 85, 0.1)',
              color: 'rgba(43, 193, 85, 1)',
              borderRadius: '5px',
              boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
              width: '8rem',
              marginRight: '1rem',
            }}
          >
            <strong style={{ fontSize: '0.8rem' }}>Export to Excel</strong>
          </div>
        </div>

        <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
          {' '}
          {/* Scrollable container */}
          <Table variant="simple" color="gray.500" mb="24px" mt="12px">
            <Thead>
              <Tr>
                <Th borderColor={borderColor} pe="10px">
                  Sr
                </Th>
                {columns.map((heading, index) => (
                  <Th
                    key={index}
                    borderColor={borderColor}
                    cursor="pointer"
                    pe="10px"
                  >
                    <Flex
                      justifyContent="space-between"
                      align="center"
                      fontSize={{ sm: '10px', lg: '12px' }}
                      color="gray.400"
                    >
                      {heading.text}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {loading && (
                <Tr>
                  <Td colSpan={columns.length + 1} textAlign="center">
                    <div className="loader"></div>
                  </Td>
                </Tr>
              )}
              {dataToDisplay.map((e, ind) => (
                <Tr
                  key={ind}
                  style={{
                    border: 'none',
                    height: '3rem',
                    backgroundColor: 'white',
                    color: 'black',
                  }}
                >
                  <Td
                    style={{ border: 'none' }}
                    className="text-center align-middle"
                    fontSize={{ sm: '10px', lg: '13px' }}
                  >
                    {ind + 1}
                  </Td>
                  {columns.map((cell) => (
                    <Td
                      key={cell.dataField}
                      style={{ border: 'none' }}
                      className="text-center align-middle"
                      fontSize={{ sm: '10px', lg: '13px' }}
                    >
                      {cell.dataField === 'status' ? (
                        <>
                          {e[cell.dataField].toString().toLowerCase() ===
                            'refunded' ||
                          e[cell.dataField].toString().toLowerCase() ===
                            'refund' ? (
                            <label
                              style={{
                                padding: '0.5rem',
                                textAlign: 'center',
                                borderRadius: '.4rem',
                                backgroundColor: 'rgba(0, 123, 255, 0.1)',
                                color: 'rgba(0, 123, 255, 1)',
                                border: '1px solid rgba(0, 123, 255, 1)',
                                fontSize: '0.8rem',
                              }}
                            >
                              {e[cell.dataField]}
                            </label>
                          ) : e[cell.dataField].toString().toLowerCase() ===
                            'success' ? (
                            <label
                              style={{
                                padding: '0.5rem',
                                textAlign: 'center',
                                borderRadius: '.4rem',
                                backgroundColor: '#28a745', // Green for success
                                color: '#fff',
                                border: '1px solid rgba(43, 193, 85, 1)',
                                fontSize: '0.8rem',
                              }}
                            >
                              {e[cell.dataField]}
                            </label>
                          ) : e[cell.dataField]
                              .toString()
                              .toLowerCase()
                              .includes('expired') ? (
                            <label
                              style={{
                                padding: '0.5rem',
                                textAlign: 'center',
                                borderRadius: '.4rem',
                                backgroundColor: '#C80036', // Red for expired
                                color: '#fff',
                                border: '1px solid #C80036',
                                fontSize: '0.8rem',
                              }}
                            >
                              {e[cell.dataField]}
                            </label>
                          ) : e[cell.dataField].toString().toLowerCase() ===
                            'failed' ? (
                            <label
                              style={{
                                padding: '0.5rem',
                                textAlign: 'center',
                                borderRadius: '.4rem',
                                backgroundColor: '#FFD700', // Yellow for failed
                                color: '#fff',
                                border: '1px solid #FFD700',
                                fontSize: '0.8rem',
                              }}
                            >
                              {'Failed'}
                            </label>
                          ) : (
                            <label
                              style={{
                                padding: '0.5rem',
                                textAlign: 'center',
                                borderRadius: '.4rem',
                                backgroundColor: '#808080', // Grey for unknown status
                                color: '#fff',
                                border: '1px solid #808080',
                                fontSize: '0.8rem',
                              }}
                            >
                              {e[cell.dataField]}
                            </label>
                          )}
                        </>
                      ) : (
                        e[cell.dataField] || 'NA'
                      )}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Pagination
            page={currentPage}
            limit={pages}
            callback={(page) => {
              setCurrentPage(page);
            }}
            count={dataToDisplay.length}
            total={total}
            callback2={(offsetValue) => {
              setOffset(offsetValue);
            }}
          />
        </div>
      </Box>
    </Card>
    // <div style={{ background: "white" }}>

    //   <div className="row m-2 p-0 pt-4 align-items-center" id="parentFilters">
    //

    //     </div>

    //   <div className="">
    //     <table
    //       className="table"
    //       style={{ fontFamily: "Poppins", border: "1px solid #bfbcbc" }}
    //     >
    //       <tr style={{ backgroundColor: "white", color: "black", border: "0.1px solid rgb(191 188 188)" }}
    //       >
    //         <th
    //           className="text-center bg-white text-black py-3"
    //           > {"Sr"}</th>
    //         {columns.map((heading, index) => (
    //           <th key={index}
    //           className="text-center bg-white text-black py-3"
    //           >{heading.text}</th>
    //         ))}
    //       </tr>
    //       {loading && (
    //         <div className="loader-container d-flex justify-content-center align-items-center">
    //           <div className="loader"></div>
    //         </div>
    //       )} {dataToDisplay.map((e, ind) => (
    //         <tr style={{ border: "0.1px solid rgb(191 188 188)" }}
    //         key={ind}>
    //           <td className="text-center align-middle" style={{ fontSize: ".8rem" , border:"none" }}>{ind + 1}</td>
    //           {columns.map((cell) => (
    //             <td  key={cell.dataField} style={{ border: "none" }}>
    //               {cell.dataField === "status" && (
    //                 <>
    //                   {(e[cell.dataField].toString().toLowerCase() === "refunded" || e[cell.dataField].toString().toLowerCase() === "refund") ? (
    //                     <label
    //                       style={{
    //                         padding: "8px",
    //                         borderRadius: ".4rem",
    //                         backgroundColor: 'rgba(0, 123, 255, 0.1)',
    //                         color: 'rgba(0, 123, 255, 1)',
    //                         border: "1px solid rgba(0, 123, 255, 1)",
    //                         borderColor: 'rgba(0, 123, 255, 1)',
    //                         fontSize: "0.8rem",
    //                       }}
    //                       className="badge badge-warning"
    //                     >
    //                       {e[cell.dataField]}
    //                     </label>
    //                   ) : (e[cell.dataField].toString().toLowerCase() == "settlementcompleted" || e[cell.dataField].toString().toLowerCase() == "success") ? (
    //                     <label
    //                       style={{
    //                         padding: "8px",
    //                         border: "1px solid",
    //                         height: "2rem",
    //                         backgroundColor: 'rgba(43, 193, 85, 0.1)',
    //                         color: 'rgba(43, 193, 85, 1)',
    //                         borderColor: 'rgba(43, 193, 85, 1)',
    //                         borderRadius: ".4rem",
    //                         fontSize: ".8rem",
    //                       }}
    //                       className="badge badge-success"
    //                     >
    //                       {e[cell.dataField]}
    //                     </label>
    //                   ) :
    //                     (e[cell.dataField] === "Received" || e[cell.dataField] === "Accepted") ? (
    //                       <label
    //                         style={{
    //                           padding: "8px",
    //                           borderRadius: ".4rem",
    //                           backgroundColor: 'rgba(255, 238, 0, 0.1)',
    //                           color: 'rgba(255, 196, 0, 1)',
    //                           border: "1px solid rgba(255, 238, 0, 1)",
    //                           borderColor: 'rgba(255, 238, 0, 1)',
    //                           fontSize: "0.8rem",
    //                         }}
    //                         className="badge badge-warning"
    //                       >
    //                         {e[cell.dataField]}
    //                       </label>
    //                     ) :
    //                       e[cell.dataField].toString().toLowerCase() === "failed" ? (
    //                         <label
    //                           style={{
    //                             padding: "8px",
    //                             borderRadius: ".4rem",
    //                             backgroundColor: 'rgba(255, 0, 0, 0.1)',
    //                             color: 'rgba(255, 0, 0, 1)',
    //                             border: "1px solid rgba(255, 0, 0, 1)",
    //                             borderColor: 'rgba(255, 0, 0, 1)',
    //                             fontSize: "0.8rem",
    //                           }}
    //                           className="badge badge-danger"
    //                         >
    //                           Failed
    //                         </label>
    //                       ) :
    //                         e[cell.dataField] === "0" ? (
    //                           <label
    //                             style={{
    //                               padding: "8px",
    //                               borderRadius: ".4rem",
    //                               backgroundColor: 'rgba(0, 123, 255, 0.1)',
    //                               color: 'rgba(0, 123, 255, 1)',
    //                               border: "1px solid rgba(0, 123, 255, 1)",
    //                               borderColor: 'rgba(0, 123, 255, 1)',
    //                               fontSize: "0.8rem", textAlign: "center"
    //                             }}
    //                             className="badge badge-warning "
    //                           >
    //                             Virtual
    //                           </label>
    //                         ) : (
    //                           <label
    //                             style={{
    //                               padding: "8px",
    //                               borderRadius: ".4rem",
    //                               backgroundColor: 'rgba(255, 238, 0, 0.1)',
    //                               color: 'rgba(255, 196, 0, 1)',
    //                               border: "1px solid rgba(255, 238, 0, 1)",
    //                               borderColor: 'rgba(255, 238, 0, 1)',
    //                               fontSize: "0.8rem", textAlign: "center"
    //                             }}
    //                             className="badge badge-warning "
    //                           >
    //                             {e[cell.dataField]}
    //                           </label>
    //                         )

    //                   }
    //                 </>
    //               )}
    //               {cell.dataField !== "status" && e[cell.dataField]}
    //             </td>
    //           ))}
    //         </tr>
    //       ))}
    //     </table>
    //     {/* {ledger.length <= 0 && <NoDataFound />} */}

    //   </div>
    //   {/* <Pagination
    //         page={currentPage}
    //         limit={pages}
    //         callback={(page) => {
    //           setCurrentPage(page);
    //         }}
    //         count={dataToDisplay.length}
    //         total={total}
    //         callback2={(offsetValue) => {
    //           setOffset(offsetValue);
    //         }}
    //       /> */}
    // </div>
  );
};

export const Ledger = ({ setDetails }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  // const { removeMyAccountInfo, setTransactionLoaderState } = useContext(ReduxContext);

  const [ledger, setLedger] = useState([]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() : '0' + date.getMonth()
    }-${date.getDate() > 9 ? date.getDate() : '0' + date.getDate()}`;
  };
  const getAllVirtualPayments = async () => {
    const accessToken = sessionStorage.getItem('accessToken');
    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${accessToken}`);
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append(
      'Cookie',
      '.AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iwOgwEj6EBN0jpchgFhuiV_O6Tcuf6QLM3cjarWosB3o17JTbrLuvcOmIJGLw0OwV-JJr2mhON-MIsfNGVplhmoJMIoQQrIyv6dzlCW8_eOvZw0ahT_icJcGsoHfR-1FLppZoY2SWzX8NUi9aabidVqbnsJjig4BpI2oPpnu73FH5iMh2Kfuz2xWcPSdx08C7D_6Ygts4I9XapyCvm0OVKguJ3fWPFHBgoY-PSnQ4KNzeh-OI4TzaffhkWCBwgBLtA6ybohQ1cqY5c2Y2EPo-sgAft8xvCDg9pibGVqcUdtEkqHj-BKKBdLijXEW-P7Q9e-T-wL07LWdnOnMPF9YCns79c8kBgyHTiVi82lFdqqIZtzZnhJsZoVhf9vsGaD37-6zhe4sDOkUJf4WCLur6gGQB8LQrfK9RZg30X0OvMv7ZCOtC4SKxgXiyk44NtkqY9oP_cVSb00F_E80WkQhk1A4nUfPalYivPP3EPF45TtK2hlhfdvdw_G1rMLrDiTbaIj-yvW5831ualRbsVUhVSHiUZzLTshMjUg-r_o5FNk4OSR2EyJm2l1WGwLdtZccqzvuyG_UzV9XiIYA658Nqy0iQX-ckKK4yR0yLPGXkmyx1MzMh9RjVPJwsRmhnIKqPE4eOFQNdJDYowJQhEQIMZV',
    );

    var raw = JSON.stringify({
      pageIndex: currentPage,
      pageSize: 10000000,
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };
    try {
      // setTransactionLoaderState(true)
      const res = await fetch(
        'https://apitranxt.paynnow.com/api/Payments/GetUserledger',
        requestOptions,
      );
      console.log('response', res);
      if (res.status == 401) {
        // removeMyAccountInfo();
      } else if (res.ok) {
        const data = await res.json();
        console.log(data);
        if (data && data.length > 0) {
          const dataLoad = data.map((acc) => {
            return {
              ...acc,
              upi: `${acc.beneAccountNo}@yesbankltd`,
              date: new Date(acc.createdOn),
            };
          });
          dataLoad.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
          });
          console.log(dataLoad);
          setLedger(dataLoad);
          setDataToDisplay(dataLoad);
        }
      }
      // setTransactionLoaderState(false)
      // setPayments(JSON.parse(body).data);
    } catch (error) {
      // setTransactionLoaderState(false)
      console.log('401 catched', error);
    }
  };

  const openInNewTab = () => {
    const newWindow = window.open(
      `/pages/print?id=090422191784660&type=DMT`,
      '',
      "height=600,width=800'",
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + 999) / 1000));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = 999;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * 1000;
    const endIdx = currentPage * 1000 - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  useEffect(() => {
    // getAllTransactions();
  }, [currentPage]);

  useEffect(() => {
    setPages(Math.floor((ledger.length + 999) / 1000));
    const startIdx = 0;
    const endIdx = 999;
    setTotal(ledger.length);
    const filteredData = ledger.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [ledger]);

  // did mount
  useEffect(() => {
    getAllVirtualPayments();
  }, []);
  console.log(ledger);
  return (
    <div style={{ background: 'white' }}>
      <div className="row">
        <div className="col-7 m-2">
          <div
            className="d-flex"
            style={{
              cursor: 'pointer',
              border: '1px solid',
              height: '2.5rem',
              width: '11rem',
              backgroundColor: 'rgba(43, 193, 85, 0.1)',
              color: 'rgba(43, 193, 85, 1)',
              bordercolor: 'rgba(43, 193, 85, 1)',
              borderRadius: '.4rem',
            }}
            // onClick={() => {
            //   ExportToExcel(
            //     `Ledger_Report_${now()}`,
            //     excelMapping(dataToDisplay, columns)
            //   );
            // }}
          >
            {/* <img alt=""
              src={require("../../assets/images/excel.png")}
              style={{ width: "1.6rem", marginLeft: "15px" }}
            /> */}
            <strong
              className="d-flex align-items-center"
              style={{ marginLeft: '2px' }}
            >
              Export to Excel
            </strong>
          </div>
        </div>

        <div className=" col m-2 daterange-picker">
          <Form.Group>
            <Form.Control
              type="text"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder="Search"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              value={searchText}
            />
          </Form.Group>
          <Form.Group style={{ marginLeft: '10px', width: '8rem' }}>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="form-control"
              placeholderText="Start Date"
              style={{ borderRadius: '0px' }}
            />
          </Form.Group>

          <Form.Group style={{ marginLeft: '10px', width: '8rem' }}>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className="form-control"
              placeholderText="End Date"
              style={{ borderRadius: '0px' }}
            />
          </Form.Group>
          <div style={{ marginLeft: '10px' }}>
            {' '}
            <Button
              className=""
              style={{ borderRadius: '.4rem' }}
              variant="primary"
              onClick={() => {}}
            >
              {'Search'}
            </Button>
          </div>
        </div>
      </div>

      <div className="m-0 p-0 card mx-3 center-block scroll-inner">
        <table
          className="dashboard-table-style"
          style={{ width: '100%', overflowX: 'auto' }}
        >
          <tr>
            <th
              className="text-center"
              style={{
                fontSize: '0.9rem',
                background: '#f5f6f8',
                color: '#586275',
              }}
            >
              {' '}
              {'Sr'}
            </th>
            {columns.map((heading, index) => (
              <th
                key={index}
                className="text-center"
                style={{
                  fontSize: '0.9rem',
                  background: '#f5f6f8',
                  color: '#586275',
                }}
              >
                {heading.text}
              </th>
            ))}
          </tr>
          {dataToDisplay.map((e, ind) => (
            <tr key={ind}>
              <td style={{ fontSize: '1rem' }}>{ind + 1}</td>
              {columns.map((cell) => (
                <td
                  key={cell.dataField}
                  style={{ fontSize: '.8rem', color: cell.color }}
                >
                  {cell.dataField === 'status' && (
                    <>
                      {(e[cell.dataField].toString().toLowerCase() ===
                        'refunded' ||
                        e[cell.dataField].toString().toLowerCase() ===
                          'refund') && (
                        <label
                          style={{
                            padding: '8px',
                            borderRadius: '.4rem',
                            backgroundColor: 'rgba(0, 123, 255, 0.1)',
                            color: 'rgba(0, 123, 255, 1)',
                            border: '1px solid rgba(0, 123, 255, 1)',
                            borderColor: 'rgba(0, 123, 255, 1)',
                            fontSize: '0.8rem',
                          }}
                          className="badge badge-warning"
                        >
                          {e[cell.dataField]}
                        </label>
                      )}
                      {e[cell.dataField] === 'SettlementCompleted' && (
                        <label
                          style={{
                            padding: '8px',
                            border: '1px solid',
                            height: '2rem',
                            backgroundColor: 'rgba(43, 193, 85, 0.1)',
                            color: 'rgba(43, 193, 85, 1)',
                            borderColor: 'rgba(43, 193, 85, 1)',
                            borderRadius: '.4rem',
                            fontSize: '.8rem',
                          }}
                          className="badge badge-success"
                        >
                          {e[cell.dataField]}
                        </label>
                      )}
                      {(e[cell.dataField] === 'Received' ||
                        e[cell.dataField] === 'Accepted') && (
                        <label
                          style={{
                            padding: '8px',
                            borderRadius: '.4rem',
                            backgroundColor: 'rgba(0, 123, 255, 0.1)',
                            color: 'rgba(0, 123, 255, 1)',
                            border: '1px solid rgba(0, 123, 255, 1)',
                            borderColor: 'rgba(0, 123, 255, 1)',
                            fontSize: '0.8rem',
                          }}
                          className="badge badge-warning"
                        >
                          {e[cell.dataField]}
                        </label>
                      )}
                      {e[cell.dataField].toString().toLowerCase() ===
                        'failed' && (
                        <label
                          style={{
                            padding: '8px',
                            borderRadius: '.4rem',
                            backgroundColor: 'rgba(0, 123, 255, 0.1)',
                            color: 'rgba(0, 123, 255, 1)',
                            border: '1px solid rgba(0, 123, 255, 1)',
                            borderColor: 'rgba(0, 123, 255, 1)',
                            fontSize: '0.8rem',
                          }}
                          className="badge badge-danger"
                        >
                          Failed
                        </label>
                      )}
                      {e[cell.dataField] === '0' && (
                        <label
                          style={{
                            padding: '8px',
                            borderRadius: '.4rem',
                            backgroundColor: 'rgba(0, 123, 255, 0.1)',
                            color: 'rgba(0, 123, 255, 1)',
                            border: '1px solid rgba(0, 123, 255, 1)',
                            borderColor: 'rgba(0, 123, 255, 1)',
                            fontSize: '0.8rem',
                          }}
                          className="badge badge-warning"
                        >
                          Virtual
                        </label>
                      )}
                    </>
                  )}
                  {cell.dataField !== 'status' && e[cell.dataField]}
                </td>
              ))}
            </tr>
          ))}
        </table>
      </div>
      {/* <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
      /> */}
    </div>
  );
};

export default Transactions;

// export const Pagination = ({
//   page,
//   limit,
//   callback,
//   total,
//   count,
//   callback2,
// }) => {
//   console.table({ page, limit, callback });
//   return (
//     <div
//     className="row"
//     style={{
//       display: "flex",
//       alignItems: "center",
//       width: "100%",
//       margin: "10px 0px",
//       justifyContent: "space-between",
//     }}
//   >
//     <div
//       className="d-flex"
//       style={{
//         // border: "1px solid #33334d",
//         borderRadius: "4px",
//         marginTop: "5px",
//         overflow: "hidden",
//       }}
//     >
//       <select
//         className="form-control"
//         style={{ border: "none", outline: "none" }}
//         aria-label="offset"
//         onChange={(e) => {
//           callback2(e.target.value);
//         }}
//       >
//         {offsetArr.map((offset) => (
//           <option value={offset}>{offset}</option>
//         ))}
//       </select>
//       {page - 2 > 0 && (
//         <Button
//           onClick={() => {
//             callback(page - 1);
//           }}
//           variant="light"
//           style={{ border: "none", backgroundColor: "#f8f9fa" }}
//         >
//           Previous
//         </Button>
//       )}
//       {page - 1 > 0 && (
//         <Button
//           onClick={() => {
//             callback(page - 1);
//           }}
//           variant="light"
//           style={{ border: "none", backgroundColor: "#f8f9fa" }}
//         >
//           {(page - 1).toString()}
//         </Button>
//       )}
//       <Button
//         variant="light"
//         style={{
//           backgroundColor: "#33334d",
//           color: "#fff",
//           borderRadius: "0px",
//         }}
//       >
//         {page.toString()}
//       </Button>
//       {page + 1 <= limit && (
//         <Button
//           onClick={() => {
//             callback(page + 1);
//           }}
//           variant="light"
//           style={{
//             border: "none",
//             backgroundColor: "#f8f9fa",
//             boxShadow: "none",
//           }}
//         >
//           Next
//         </Button>
//       )}
//     </div>
//     <div>
//       <strong style={{color:"#33334d"}}>{`Showing ${count} of ${total} entries`}</strong>
//     </div>
//   </div>

//   );
// };
